<template>
<div class=" w-full grid grid-cols-12">
    <!-- <div class="col-span-1 px-3 py-2 bg-emerald-200 border-b border-r border-l border-gray-300">{{ displayType }}</div> -->
    <div class="col-span-2 px-3 py-2 bg-emerald-200 border-b border-r border-l border-gray-300">{{ displayName }}</div>
    <div class="col-span-2 flex justify-start px-3 py-2 bg-emerald-200 border-b border-r border-gray-300">{{ dueDate }}</div>
    <div class="col-span-2 flex justify-start px-3 py-2 bg-emerald-200 border-b border-r border-gray-300">{{ resolvedDate }}</div>
    <div class="col-span-1 px-3 py-2 bg-emerald-200 border-b border-r border-gray-300">{{ event.user ? `${event.user.first_name} ${event.user.last_name}` : '--' }} ({{ event.created_by ? `${event.created_by.first_name.substring(0, 1)}${event.created_by.last_name.substring(0, 1)}` : '--' }})</div>
    <div class="col-span-1 px-3 py-2 bg-emerald-200 border-b border-r text-center border-gray-300">{{ status }}</div>
    <div class="col-span-3 px-3 py-2 bg-emerald-200 border-b border-r border-gray-300">{{ event.action_notes ? event.action_notes : '--' }}</div>
    <div class="col-span-1 px-3 py-2 bg-emerald-200 border-b border-r border-gray-300 flex justify-center">
        <div v-if="event.action_details" class="flex justify-center items-center">
            <button @click="showDetailsModal" class=" text-dark-primary  px-2 py-1 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                </svg>

            </button>
            <InfoModal
                v-if="event.action_details"
                :is-modal-visible="visibleDetailsModal"
                @close="closeDetailsModal"
            >
                <template #header>
                Detail Correspondence
                </template>
                <template #body>
                    <div class="w-full whitespace-pre-wrap break-before-auto text-start">
                        <p >{{ event.action_details }}</p>
                    </div>
                </template>
            </InfoModal>
        </div>
        <div v-else class="flex justify-center items-center">
            --
        </div>
    </div>
</div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import { formatDate } from '@/composables/formatDate';
import InfoModal from '../InfoModal.vue';

const props = defineProps(['event'])

const visibleDetailsModal = ref(false)

function showDetailsModal() {
    visibleDetailsModal.value = true
}

const displayName = computed(() => {
    if(props.event){
        return props.event.resolved_date ? props.event.action.resolved_label : props.event.action.unresolved_label
    } else {
        return '--'
    }
})

// const displayType = computed(() => {
//     if(props.event){
//         return props.event.status == 'Open' ? 'To-do' : props.event.status == 'Done' & props.event.action.resolved_type == 'event' ? 'Event' : props.event.status == 'Done' & props.event.action.resolved_type == 'action' ? 'Action' : '--'
//     } else {
//         return '--'
//     }
// })

const dueDate = computed(() => {
    if(props.event){
        return props.event.due_date ? formatDate(props.event.due_date): '--'
    } else {
        return '--'
    }
})

const resolvedDate = computed(() => {
    if(props.event){
        return props.event.resolved_date ? formatDate(props.event.resolved_date): '--'
    } else {
        return '--'
    }
})

const status = computed(() => {
    return props.event.resolved_date ? 'Done' : 'Open'
})

function closeDetailsModal() {
    visibleDetailsModal.value = false
}

</script>