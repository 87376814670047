<template>
    <TablePage
        class="px-0 2xl:px-10 pb-2"
        :module-name="moduleName"
        :response="response"
        :isLoading="isLoading"
        :tableHeaders="tableHeaders"
        :filters="companyCampaignChangeRequestsFilter"
        :bulkOperations="companyCampaignChangeRequestsBulkOperations"
        :menuOptions="menuOptions"
        @refetch-results="loadCompanyCampaignChangeRequests"
        @clear-filter="clearFilter"
        @change-filters="setFilters"
        @set-filter-options="setFilterOptions"
    >
        <template #bulk-operations="{ events: { hideBulkActionMenu, hideBulkOperations, clearSelectAllClearSelectedResults, refetchResults }, bulkAction, fetchFilter, selectedResults }">
            <CompanyCampaignChangeBulkOperations
                :bulkAction="bulkAction"
                :selectedResults="selectedResults"
                :campaigns="campaigns"
                @cancel-bulk-operation="hideBulkActionMenu"
                @close-bulk-operation="hideBulkOperations"
                @clear-selected-results="clearSelectAllClearSelectedResults"
                @refetch-results="refetchResults(fetchFilter)"
            />
        </template>
        <!-- <template #row-form="{ events: { closeFormRow, refetchResults }, index, fetchFilter, userPermissions }">
        </template> -->
        <template #table-row="{ events: { addSelectedResult, removeSelectedResult, refetchResults }, selectAll, fetchFilter, userPermissions }">
            <CompanyCampaignChangeRequestRow
                v-for="companyCampaignChangeRequest in response.data.results"
                :key="companyCampaignChangeRequest.id"
                :companyCampaignChangeRequest="companyCampaignChangeRequest"
                :selectAll="selectAll"
                :userPermissions="userPermissions"
                :is-loading="isLoading"
                :campaigns="campaigns"
                @refetch-results="refetchResults(fetchFilter)"
                @add-selected-company-campaign-change-request="addSelectedResult"
                @remove-selected-company-campaign-change-request="removeSelectedResult"
                @loading="setLoading"
            />
        </template>
    </TablePage>
</template>

<script setup>
import { ref, reactive, } from 'vue';
import { useStore } from 'vuex';
import { getCompanyCampaignChangeRequests } from '@/services/CompanyCampaignChangeRequest'
import TablePage from "@/components/base-components/TablePage";
import CompanyCampaignChangeRequestRow from './CompanyCampaignChangeRequestRow.vue';
import CompanyCampaignChangeBulkOperations from './CompanyCampaignChangeBulkOperations.vue';
import { formatDate } from '@/composables/formatDate';
import { getUsersByGroup, getUsers } from '@/services/User'
import { getCampaigns, getActiveCampaigns } from '@/services/Campaign';
import { get } from '@vueuse/core';

const store = useStore()
const isLoading = ref(false)
const moduleName = ref('Company Campaign Change Requests')
const response = ref(null)
const tableHeaders = ref([
    {
        value: 'company',
        label: 'Company',
        class: 'p-0 w-36 lg:w-36  whitespace-nowrap'
    },
    {
        value: 'company_url',
        label: '🌐︎',
        class: 'p-0 w-5 lg:w-5  whitespace-nowrap'
    },
    {
        value: 'old_campaign',
        label: 'From Campaign',
        class: 'p-0 w-20 lg:w-40  whitespace-nowrap '
    },
    {
        value: 'tier',
        label: 'Tier @ Request',
        class: 'p-0 w-24 lg:w-24  whitespace-nowrap '
    },
    {
        value: 'new_campaign',
        label: 'To Campaign',
        class: 'p-0 w-20 lg:w-40  whitespace-nowrap',
    },
    {
        value: 'company_url',
        label: 'Copy CRM',
        class: 'p-0 w-16  whitespace-nowrap'
    },
    {
        value: 'requested_by',
        label: 'Requestor',
        class: 'p-0 w-24  whitespace-nowrap'
    },
    {
        value: 'owners',
        label: 'Owners',
        class: 'p-0 w-24  whitespace-nowrap'
    },
    {
        value: 'request_status',
        label: 'Status',
        class: 'p-0 w-12   whitespace-nowrap',
    },
    {
        value: 'created_at',
        label: 'Request Date',
        class: 'p-0 w-16  whitespace-nowrap',
    },
    {
        value: 'resolved_at',
        label: 'Resolved At',
        class: 'p-0 w-16  whitespace-nowrap',
    },
    {
        value: 'action',
        label: 'Actions',
        class: 'p-0 w-8 lg:w-16'
    },
])
// const defaultOrdering = ref(['-date'])

const campaigns = ref(null)

async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getCampaigns(token)
    campaigns.value = result
}

loadCampaigns()

const companyCampaignChangeRequestsFilter = reactive({
    requestStatus: {
        name: "request_status",
        label: "Request Status",
        value: "open",
        defaultValue: "open",
        options: [
            {value: null, label: "------------"},
            {value: "open", label: "Open"},
            {value: "accepted", label: "Accepted"},
            {value: "rejected", label: "Rejected"},  
        ],
        widget: 'list-box',
        multiSelect: false
    },
    campaignOwner: {
        name: "campaign_owner",
        label: "Campaign Owner",
        value: [store.state.auth.user.id],
        defaultValue: [store.state.auth.user.id],
        options: [
            {value: "clear", label: "------------"},
            {value: store.state.auth.user.id, label: store.state.auth.userFullName}
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setCampaignOwnerOptions.bind(null),
        awaitingOptions: false
    },
    requestedBy: {
        name: "requested_by",
        label: "Requested By",
        value: null,
        defaultValue: null,
        options: [
            {value: "clear", label: "------------"}
        ],
        widget: 'list-box',
        multiSelect: false,
        setOptionsMethod: setCampaignRequestorOptions.bind(null),
        awaitingOptions: false
    },
    oldCampaign: {
        name: "old_campaign",
        label: "From Campaign",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"},
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setCampaignOptions.bind(null),
        awaitingOptions: false
    },
    newCampaign: {
        name: "new_campaign",
        label: "To Campaign",
        value: [],
        defaultValue: [],
        options: [
            {value: "clear", label: "------------"},
        ],
        widget: 'list-box',
        multiSelect: true,
        setOptionsMethod: setCampaignOptions.bind(null),
        awaitingOptions: false
    }
})

const companyCampaignChangeRequestsBulkOperations = reactive({
    approveRequests: {
        label: 'Approve Company Campaign Change Requests',
        modelPath: 'company_campaign_change_requests',
        endpoint: 'bulk_approve_requests',
        message: 'Click continue to approve the selected change requests.'
    },
    rejectRequests: {
        label: 'Reject Company Campaign Change Requests',
        modelPath: 'company_campaign_change_requests',
        endpoint: 'bulk_reject_requests',
        message: 'Add a rejection reason for the selected campaign change requests.',
    }
})

function setLoading(){
    isLoading.value = true
}

const menuOptions = reactive({
    selectAll: true,
    paginationArrows: true,
    totalResults: false,
    pageSize: true,
    refreshButton: true,
    filters: true,
    bulkOperations: true,
    addFormRows: true,
})

function setFilters(updatedFilters) {
    companyCampaignChangeRequestsFilter.requestStatus = updatedFilters.requestStatus;
    companyCampaignChangeRequestsFilter.campaignOwner = updatedFilters.campaignOwner;
}

async function loadCompanyCampaignChangeRequests(fetchFilter='') {
    isLoading.value = true;
    const token = store.state.auth.token
    response.value = await getCompanyCampaignChangeRequests(token, fetchFilter);
    isLoading.value = false;
}

async function setFilterOptions(filterName, queryParams='') {
    console.log('setFilterOptions', filterName)
    if(!companyCampaignChangeRequestsFilter[filterName].awaitingOptions){
        if(companyCampaignChangeRequestsFilter[filterName].setOptionsMethod){
            companyCampaignChangeRequestsFilter[filterName].awaitingOptions = true
            companyCampaignChangeRequestsFilter[filterName].setOptionsMethod(queryParams)
            setTimeout(() => {
                companyCampaignChangeRequestsFilter[filterName].awaitingOptions = false;
            }, 30000)
        }
    }
}

function clearFilter(filterName) {
    if(Array.isArray(companyCampaignChangeRequestsFilter[filterName].value)){
        companyCampaignChangeRequestsFilter[filterName].value = []
    } else {
        companyCampaignChangeRequestsFilter[filterName].value = null
    }
}

// async function setCampaignOwnerOptions() {
//     const token = store.state.auth.token
//     const result = await getAllCampaignOwners(token)
//     companyCampaignChangeRequestsFilter.campaignOwner.options = [{value: "clear", label: "------------"}, ...result.clients.map((client) => {
//             return {value: client.id, label: client.client_name}
//         }
//     )]
// }


async function setCampaignOwnerOptions() {
    const payload = {
        endpoint: 'list_users',
        group: 'management_group&group=dev_group&group=account_manager_group',
        token: store.state.auth.token
    }
    const result = await getUsersByGroup(payload)
    companyCampaignChangeRequestsFilter.campaignOwner.options = [{value: "clear", label: "------------"}, ...result.users.map((user) => {
            return {value: user.id, label: `${user.first_name} ${user.last_name}`}
            }
        )]    
    companyCampaignChangeRequestsFilter.requestedBy.options = [{value: "clear", label: "------------"}, ...result.users.map((user) => {
            return {value: user.id, label: `${user.first_name} ${user.last_name}`}
            }
        )]    
}

async function setCampaignRequestorOptions() {
    const payload = {
        endpoint: '?has_campaign_change_requests=True',
        token: store.state.auth.token
    }
    const result = await getUsers(payload)
    companyCampaignChangeRequestsFilter.campaignOwner.options = [{value: "clear", label: "------------"}, ...result.users.map((user) => {
            return {value: user.id, label: `${user.first_name} ${user.last_name}`}
            }
        )]    
    companyCampaignChangeRequestsFilter.requestedBy.options = [{value: "clear", label: "------------"}, ...result.users.map((user) => {
            return {value: user.id, label: `${user.first_name} ${user.last_name}`}
            }
        )]    
}

async function setCampaignOptions() {
  const token = store.getters['auth/showToken']
  const result = await getActiveCampaigns(token)
  companyCampaignChangeRequestsFilter.oldCampaign.options = [{value: "clear", label: "------------"}, {value: "null", label: "Unassigned"}, ...result.map((campaign) => {
      return {value: campaign.id, label: campaign.campaign_name}
  })]
  companyCampaignChangeRequestsFilter.newCampaign.options = [{value: "clear", label: "------------"}, ...result.map((campaign) => {
      return {value: campaign.id, label: campaign.campaign_name}
  })]
}


</script>