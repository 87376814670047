<template>
    <TableRow
        :isSelected="isSelected" :class="{'cursor-wait': loadingCompany}"
    >
        <TableCell>
            <div
                @mouseover="showSelectBox"
                @mouseleave="hideSelectBox"
                class="flex justify-start items-center p-2 pl-4 w-[4.5rem]"
            >
                <div v-if="visibleSelectBox" class="flex justify-start items-center">
                    <input type="checkbox" id="checkbox" :checked="isSelected" @change="toggleSelected"/>
                </div>
                <div v-else class="whitespace-nowrap flex justify-start items-center w-full">
                    {{ companyCampaignChangeRequest.id }}
                </div>
            </div>

        </TableCell>
        <TableCell>
            <div @click="getCompanyandOpenForm" class="whitespace-nowrap w-full p-2 hover:underline hover:cursor-pointer">
               {{ company.company_name }}
            </div>
            <base-modal :is-form-visible="isCompanyFormVisible" @close="closeCompanyForm">
          <template #header>
            <h2 class="text-lg">{{ company.company_name }} {{ company.id }}</h2>
          </template>
          <template #body>
            <EditCompany
              :company="detailCompany"
              :campaigns="campaigns"
              @close="closeCompanyForm"
              :default-tab="defaultTab"
            />
          </template>
        </base-modal>
        </TableCell>
        <TableCell >
            <div class="whitespace-nowrap w-full p-2">
                <a v-if="companyHasUrl" target="_blank" :href="company.website_url">
                    🌐︎
                </a>
                <span v-else>--</span>
            </div>
        </TableCell>
        <TableCell >
            <div class="whitespace-nowrap w-full p-2">
                {{ oldCampaign }}
            </div>
        </TableCell>
        <TableCell >
            <div class="whitespace-nowrap w-full p-2">
                {{ tier }}
            </div>
        </TableCell>
        <TableCell >
            <div class="whitespace-nowrap w-full p-2">
                {{ newCampaign }}
            </div>
        </TableCell>
        <TableCell >
            <div class="whitespace-nowrap w-full p-2">
                {{ companyCampaignChangeRequest.copy_crm_details ? 'True' : 'False' }}
            </div>
        </TableCell>
        <TableCell >
            <div class="whitespace-nowrap w-full p-2">
                {{ requestedBy }}
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap p-2 flex flex-col">
                <div v-for="owner in companyCampaignChangeRequest.old_campaign.owners" :key="owner.id">
                    {{ owner.first_name }} {{ owner.last_name }}
                </div>
            </div>
        </TableCell>
        <TableCell>
            <div v-if="companyCampaignChangeRequest.request_status === 'rejected'"
                @mouseover="showRejectionReasonPopover"
                @mouseleave="hideRejectionReasonPopover"
                ref="rejectionReasonBoundary"
                class="whitespace-nowrap p-2 hover:cursor-help"
            >
            {{ companyCampaignChangeRequest.request_status }}
                <base-popover-hover-small
                    v-if="visibleRejectionReasonPopover"
                    :xPosition="xPosition"
                    :yPosition="yPosition"
                    class="w-full mr-24"
                >
                    <template v-slot:title>
                        Rejection Reason:
                    </template>
                    <template v-slot:body>
                        <div class="max-h-72 overflow-auto">
                            <span  class="inline whitespace-pre-wrap max-h-96 overflow-auto">{{ companyCampaignChangeRequest.rejection_reason }}</span>
                        </div>
                    </template>
                </base-popover-hover-small>
            </div>
            <div v-else-if="companyCampaignChangeRequest.request_status === 'open' && companyCampaignChangeRequest.change_request_reason"
                @mouseover="showChangeRequestReasonPopover"
                @mouseleave="hideChangeRequestReasonPopover"
                ref="changeRequestReasonBoundary"
                class="whitespace-nowrap p-2 hover:cursor-help"
            >
            {{ companyCampaignChangeRequest.request_status }}*
                <base-popover-hover-small
                    v-if="visibleChangeRequestReasonPopover"
                    :xPosition="xPosition"
                    :yPosition="yPosition"
                    class="w-full mr-24"
                >
                    <template v-slot:title>
                        Change Request Reason:
                    </template>
                    <template v-slot:body>
                        <div class="max-h-72 overflow-auto">
                            <span  class="inline whitespace-pre-wrap max-h-96 overflow-auto">{{ companyCampaignChangeRequest.change_request_reason }}</span>
                        </div>
                    </template>
                </base-popover-hover-small>
            </div>
            <div v-else class="whitespace-nowrap p-2">
                {{ companyCampaignChangeRequest.request_status }}
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap p-2">
                {{ requestDate }}
            </div>
        </TableCell>
        <TableCell>
            <div class="whitespace-nowrap  p-2">
                {{ resolvedDate }}
            </div>
        </TableCell>
        <TableCell>
            <!-- <base-modal 
            :is-form-visible="showRejectionModal"
                @close="closeRejectionModal">
                <template #header>
                Rejection Reason
            </template>
            <template #body>
                <textarea @keyup.enter.prevent="rejectRequest" class="block p-2 w-full border border-gray-400 rounded-md" v-model="rejectionReason">
                </textarea>

            </template>
            </base-modal> -->
            <InfoModal
            :is-modal-visible="showRejectionModal"
                @close="closeRejectionModal"
            >
            <template #header>
                Rejection Reason
            </template>
            <template #body>
                <form @submit.prevent="rejectRequest" action="">
                    <div class="w-full flex flex-col space-y-2">
                        <textarea class="block p-2 w-full border border-gray-400 rounded-md" v-model="rejectionReason">
                        </textarea>
                        <div class="w-full flex justify-center">
                            <base-button-affirmative>Reject</base-button-affirmative>
                        </div>
                    </div>
                </form>
            </template>
            </InfoModal>
            <div v-if="isOwner && companyCampaignChangeRequest.request_status === 'open'" class="w-full flex justify-evenly items-center py-1.5 px-2.5">
                <button disabled class="bg-dark-secondary text-white rounded-md py-0.5 px-1 invert hover:shadow-md" v-if="isLoading">✔</button>
                <button v-else @click="approveRequest" class="bg-dark-secondary text-white rounded-md py-0.5 px-1 hover:invert hover:shadow-md">
                    ✔
                </button>
                <button disabled class="bg-dark-secondary text-white rounded-md py-0.5 px-1 invert hover:shadow-md" v-if="isLoading">✘</button>
                <button v-else @click="openRejectionModal" class="bg-dark-secondary text-white rounded-md py-0.5 px-1 hover:invert hover:shadow-md">
                    ✘
                </button>
            </div>
            <div v-else class="w-full flex justify-evenly items-center py-1.5 px-2.5">
                --
                <!-- <button disabled class="bg-dark-secondary text-white rounded-md py-0.5 px-1 invert hover:shadow-md">✔</button>
                <button disabled class="bg-dark-secondary text-white rounded-md py-0.5 px-1 invert hover:shadow-md">✘</button> -->
            </div>
        </TableCell>
    </TableRow>
</template>

<script setup>
import {ref, defineProps, defineEmits, computed, watch } from "vue";
import { useStore } from "vuex";
import TableRow from '@/components/base-components/TableRow.vue';
import TableCell from '@/components/base-components/TableCell.vue';
import EditCompany from "../EditCompany.vue";
import InfoModal from "../InfoModal.vue"
import { useToast } from 'vue-toast-notification';
import { formatDate } from "@/composables/formatDate";
import { approveCompanyCampaignChangeRequest, rejectCompanyCampaignChangeRequest } from '@/services/CompanyCampaignChangeRequest'
import {getCompanyByID} from '@/services/Company'

const store = useStore()
const $toast = useToast()

const props = defineProps([
    'companyCampaignChangeRequest',
    'selectAll',
    'isLoading',
    'campaigns'
])

const emit = defineEmits([
    'refetch-results', 
    'add-selected-company-campaign-change-request', 
    'remove-selected-company-campaign-change-request',
    'loading'
])

const rejectionReason = ref(props.companyCampaignChangeRequest.rejection_reason)
const loadingCompany = ref(false)
const isSelected = ref(props.selectAll)
const visibleSelectBox = ref(false)
const rejectionReasonBoundary = ref(null)
const changeRequestReasonBoundary = ref(null)
const visibleRejectionReasonPopover = ref(false)
const rejectionReasonPopoverOverride = ref(false)
const visibleChangeRequestReasonPopover = ref(false)
const changeRequestReasonPopoverOverride = ref(false)
const xPosition = ref(0)
const yPosition = ref(0)
const cellDisplayStyle = ref('padding:0.5rem;')
const rejectionReasonDisplayStyle = ref('white-space: nowrap;')
const isCompanyFormVisible = ref(false)
const detailCompany = ref(null)


watch(() => props.selectAll, () => {
    isSelected.value = props.selectAll
    if(props.selectAll) {
        showSelectBox()
        emit('add-selected-company-campaign-change-request', props.companyCampaignChangeRequest)
    } else if (!props.selectAll) {
        hideSelectBox()
        emit('remove-selected-company-campaign-change-request', props.companyCampaignChangeRequest)
    }
})

watch(() => props.companyCampaignChangeRequest, () => {
    if(props.companyCampaignChangeRequest) {
        rejectionReason.value = props.companyCampaignChangeRequest.rejection_reason
    }
})

async function getCompanyandOpenForm() {
    loadingCompany.value = true
    emit('loading-company', true)
    const token = store.state.auth.token
    const companyId = props.companyCampaignChangeRequest.company.id
    const result = await getCompanyByID({token: token, companyId: companyId})
    detailCompany.value = result.response.data
    console.log('detailCompany', detailCompany.value)
    isCompanyFormVisible.value = true
    loadingCompany.value = false
    emit('loading-company', false)
    // isFormVisible.value = true
}

function closeCompanyForm(){
    isCompanyFormVisible.value = false
}

const requestedBy = computed(() => {
    if(props.companyCampaignChangeRequest.requested_by) {
        return `${props.companyCampaignChangeRequest.requested_by.first_name} ${props.companyCampaignChangeRequest.requested_by.last_name}`
    } else {
        return '--'
    }
})

const resolvedDate = computed(() => {
    if(props.companyCampaignChangeRequest.resolved_at) {
        return formatDate(props.companyCampaignChangeRequest.resolved_at)
    } else {
        return '--'
    }
})

const company = computed(() => {
    if(props.companyCampaignChangeRequest.company) {
        return props.companyCampaignChangeRequest.company
    } else {
        return '--'
    }
})

const tier = computed(() => {
    if(props.companyCampaignChangeRequest.company_tier_at_time_of_request){
        return props.companyCampaignChangeRequest.company_tier_at_time_of_request.tier_name
    } else {
        return '--'
    }
})

const oldCampaign = computed(() => {
    if(props.companyCampaignChangeRequest.old_campaign) {
        return props.companyCampaignChangeRequest.old_campaign.campaign_name
    } else {
        return '--'
    }
})

const newCampaign = computed(() => {
    if(props.companyCampaignChangeRequest.new_campaign) {
        return props.companyCampaignChangeRequest.new_campaign.campaign_name
    } else {
        return 'None'
    }
})

const requestDate = computed(() => {
    if(props.companyCampaignChangeRequest.created_at) {
        return formatDate(props.companyCampaignChangeRequest.created_at)
    } else {
        return '--'
    }
})

function toggleSelected() {
    if(isSelected.value) {
        isSelected.value = false
        emit('remove-selected-company-campaign-change-request', props.companyCampaignChangeRequest)
    } else {
        isSelected.value = true
        emit('add-selected-company-campaign-change-request', props.companyCampaignChangeRequest)
    }
}

function refetchResults() {
    emit('refetch-results')
}

const isOwner = computed(() => {
    if(props.companyCampaignChangeRequest){
        return props.companyCampaignChangeRequest.old_campaign.owners.map((owner) => owner.id).includes(store.state.auth.user.id)
    }
    else {
        return false
    }
})

function showRejectionReasonPopover() {
    if(!rejectionReasonPopoverOverride.value) {
        visibleRejectionReasonPopover.value = true;
        xPosition.value = rejectionReasonBoundary.value.getBoundingClientRect().left + 50
        yPosition.value = rejectionReasonBoundary.value.getBoundingClientRect().bottom
    }

}

function showChangeRequestReasonPopover() {
    if(!changeRequestReasonPopoverOverride.value) {
        visibleChangeRequestReasonPopover.value = true;
        xPosition.value = changeRequestReasonBoundary.value.getBoundingClientRect().left + 50
        yPosition.value = changeRequestReasonBoundary.value.getBoundingClientRect().bottom
    }

}

function enableRejectionReasonPopoverOverride() {
    rejectionReasonPopoverOverride.value = true
    hideRejectionReasonPopover()
}

function disableRejectionReasonPopoverOverride() {
    rejectionReasonPopoverOverride.value = false
}

function hideRejectionReasonPopover() {
    visibleRejectionReasonPopover.value = false;
    xPosition.value = 0;
    yPosition.value = 0;
}

function hideChangeRequestReasonPopover() {
    visibleChangeRequestReasonPopover.value = false;
    xPosition.value = 0;
    yPosition.value = 0;
}

function showSelectBox() {
    visibleSelectBox.value = true
}

function hideSelectBox() {
    if(!props.selectAll & !isSelected.value) {
        visibleSelectBox.value = false
    }
}

const showRejectionModal = ref(false)

function openRejectionModal(){
    showRejectionModal.value = true
}

function closeRejectionModal(){
    showRejectionModal.value = false
}

async function approveRequest(){
    console.log('approved')
    emit('loading')
    const token = store.state.auth.token
    const result = await approveCompanyCampaignChangeRequest(token, props.companyCampaignChangeRequest.id)
    if(result.status === 200){
        $toast.open(
            {
                message: 'Approved change request',
                type: 'success',
                duration: 2000
            }
        )
        emit('refetch-results')
    } else {
        $toast.open(
            {
                message: 'Could not approve change request',
                type: 'error',
                duration: 2000
            }
        )
    }
}

const companyHasUrl = computed(() => {
    return company.value && company.value.website_url && !company.value.website_url.startsWith('http://www.no-url')
})

async function rejectRequest(){
    console.log('rejected')
    emit('loading')
    const token = store.state.auth.token
    const data = JSON.stringify({
        rejection_reason: rejectionReason.value
    })
    console.log('data', data)
    const result = await rejectCompanyCampaignChangeRequest(token, props.companyCampaignChangeRequest.id, data)
    if(result.status === 200){
        $toast.open(
            {
                message: 'Rejected change request',
                type: 'success',
                duration: 2000
            }
        )
        emit('refetch-results')
        closeRejectionModal()
    } else {
        $toast.open(
            {
                message: 'Could not reject change request',
                type: 'error',
                duration: 2000
            }
        )
    }
}

</script>