<template>
    <div>
        <div @dblclick="toggleEditable" v-if="isEditable">
        <EditEngagementInput :engagement-id="engagement.id" :input-text="engagement.invoice_due_delta" input-type="number" field-name="invoice_due_delta" @refetch-engagements="fetchNewEngagements" @toggle-editable="toggleEditable" />
        </div>
        <div @dblclick="toggleEditable"  class="text-center" v-else>
            {{ invoiceDueDelta }}
        </div>
    </div>
</template>

<script>
export default {
    name: "engagement-invoice-due-delta-cell"
}
</script>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import EditEngagementInput from './EditEngagementInput.vue';

const props = defineProps({
    engagement: Object
})

const invoiceDueDelta = computed(() => {
    if(props.engagement.invoice_due_delta){
        return `${props.engagement.invoice_due_delta} days`
    } else {
        return '--'
    }
})

const isEditable = ref(false)

const emit = defineEmits(['refetch-engagements'])

function fetchNewEngagements(){
    emit('refetch-engagements')
}

function toggleEditable(){
    isEditable.value = !isEditable.value
}

</script>

