<template>
    <div class="pl-4 flex flex-col w-full h-full">
        <div class="w-full h-[calc(100vh-14rem)] grid grid-cols-3 grid-rows-2 gap-4">
            <CRMStatusGroupCard
                v-for="(statusGroup, key, index) in statusGroups"
                :key="key"
                :index="index"
                :statusGroupName="key"
                :title="statusGroup.title"
                :progressUpdates="statusGroup.progressUpdates"
                :reload-defaults="reloadDefaults"
                :reset-report="resetReport"
                @update-progress-update="updateProgressUpdate"
                @remove-progress-update="removeProgressUpdate"
            />
        </div>
        <div class="w-full h-12 flex justiyf-start items-center border-b border-gray-300 px-2 tracking-widest font-semibold text-sm text-gray-700">
            {{ numNonRespondents }} {{ nonRespondentLabel }}
        </div>
    </div>

</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch, computed } from 'vue';
import CRMStatusGroupCard from './CRMStatusGroupCard.vue';

const props = defineProps([
    'bulkProgressUpdate',
    'progressUpdates',
    'reloadDefaults',
    'resetReport',
])
const emit = defineEmits([
    'remove-progress-update',
    'update-progress-update',
])

const statusGroups = reactive({
    activeInDialogue: {
        title: 'Active - In Dialogue',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            console.log(progressUpdate)
            return (progressUpdate.company.computed_crm_status.label == 'Active - In Dialogue' || 
                progressUpdate.company.computed_crm_status.label == 'Under LOI' || 
                progressUpdate.company.computed_crm_status.label == 'IOI Submitted');
            })
        },
    respondedCoverage: {
        title: 'Responded / Coverage',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Responded / Coverage';
        })
    },
    notNowKeepWarm: {
        title: 'Not Now / Keep Warm',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Not Now / Keep Warm';
        })
    },
    notContactedRevisit: {
        title: 'Not Contacted / Revisit',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return (progressUpdate.company.computed_crm_status.label == 'Not Contacted' || progressUpdate.company.computed_crm_status.label == 'Revist / Cut');
        })
    },
    deadAcquired: {
        title: 'Dead / Acquired',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Dead';
            // 20
        })
    },
    clientTargetPass: {
        title: 'Client / Target Pass',
        progressUpdates: props.progressUpdates.filter((progressUpdate) => {
            return progressUpdate.company.computed_crm_status.label == 'Client / Target Pass';
            // 20
        })
        // 27
    },
})

watch(() => props.progressUpdates, () => {
    // console.log('progressUpdate progressUpdates changed', props.progressUpdates)
    statusGroups.activeInDialogue.progressUpdates =  props.progressUpdates.filter((progressUpdate) => { 
        console.log(progressUpdate)
        return (progressUpdate.company.computed_crm_status.label == 'Active - In Dialogue' || 
            progressUpdate.company.computed_crm_status.label == 'Under LOI' || 
            progressUpdate.company.computed_crm_status.label == 'IOI Submitted');
    })
    statusGroups.respondedCoverage.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Responded / Coverage'; })
    statusGroups.notNowKeepWarm.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Not Now / Keep Warm'; })
    statusGroups.notContactedRevisit.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Not Contacted' || progressUpdate.company.computed_crm_status.label == 'Revist / Cut'; })
    statusGroups.deadAcquired.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Dead'; })
    statusGroups.clientTargetPass.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Client / Target Pass'; })

}, {deep:true})

// watch(() => props.bulkProgressUpdate, () => {
//     if(props.bulkProgressUpdate == 'add-all') {
//         statusGroups.activeInDialogue.progressUpdates =  props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Active - In Dialogue'; })
//         statusGroups.respondedCoverage.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Responded / Coverage'; })
//         statusGroups.notNowKeepWarm.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Not Now / Keep Warm'; })
//         statusGroups.notContactedRevisit.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Not Contacted' || progressUpdate.company.computed_crm_status.label == 'Revist / Cut'; })
//         statusGroups.deadAcquired.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Dead'; })
//         statusGroups.clientTargetPass.progressUpdates = props.progressUpdates.filter((progressUpdate) => { return progressUpdate.company.computed_crm_status.label == 'Client / Target Pass'; })
//     } else if (props.bulkProgressUpdate == 'remove-all') {
//         statusGroups.activeInDialogue.progressUpdates =  []
//         statusGroups.respondedCoverage.progressUpdates = []
//         statusGroups.notNowKeepWarm.progressUpdates = []
//         statusGroups.notContactedRevisit.progressUpdates = []
//         statusGroups.deadAcquired.progressUpdates = []
//         statusGroups.clientTargetPass.progressUpdates = []
//     }
//     emit('reset-bulk-progress-update')

// }, {deep: true})

const numNonRespondents = computed(() => {
    return props.progressUpdates ? props.progressUpdates.filter((progressUpdate) => {return progressUpdate.company.computed_crm_status.label == 'No Response'}).length : 0
})

const nonRespondentLabel = computed(() => {
    if(numNonRespondents.value == 1) {
        return 'Non-Respondent'
    }
    return 'Non-Respondents'
})
function updateProgressUpdate(progressUpdateData) {
    emit('update-progress-update', progressUpdateData)
}

function removeProgressUpdate(eventNoteID) {
    emit('remove-progress-update', eventNoteID)
}


</script>