<template>
    <base-table-row>
        <base-table-cell>
            <div class=" whitespace-nowrap">
                {{ invoice.invoice_number }} ({{ invoice.id }})
            </div>
        </base-table-cell>
        <base-table-cell>
            {{ invoice.status }}
        </base-table-cell>
        <!-- <base-table-cell>
            {{ invoice.wave_status }}
        </base-table-cell> -->
        <base-table-cell>
            <span @click="showAllClientInvoices" class="underline hover:cursor-pointer">{{ invoice.engagement.client.client_name }}</span>
            <base-modal :is-form-visible="allClientInvoicesVisible" @close="hideAllClientInvoices">
                <template #header>
                    <div>
                        {{ invoice.engagement.client.client_name }} Invoices
                    </div>
                </template>
                <template #body>
                    <div class="max-h-[95vh] overflow-auto">
                        <AllClientInvoices :client-id="invoice.engagement.client.id" />
                    </div>
                </template>
            </base-modal>
        </base-table-cell>
        <!-- <base-table-cell>
            {{ invoice.invoice_number }}
        </base-table-cell> -->
        <base-table-cell>
            <div class=" whitespace-nowrap flex justify-center">
                {{ returnDate(invoice.invoice_date) }}
            </div>
        </base-table-cell>
        <base-table-cell>
        <div class=" whitespace-nowrap flex justify-center">
            {{ returnDate(invoice.due_date) }}
        </div>
    </base-table-cell>
    <base-table-cell>
        <div class=" whitespace-nowrap flex justify-center">
            {{ formatDateTwoDigitYear(invoice.sent_date) }}
        </div>
    </base-table-cell>
    <base-table-cell>
        <div class=" whitespace-nowrap flex justify-center">
            {{ dateDelta }}
        </div>
    </base-table-cell>
    <base-table-cell>
        <div class=" whitespace-nowrap flex justify-end">
            {{ invoice.date_paid ?  returnDate(invoice.date_paid) : '--'}}
        </div>
    </base-table-cell>
    <base-table-cell>
        <div v-if="hasReminders" @click="showSentInvoiceReminders" class="underline cursor-pointer whitespace-nowrap flex justify-center" :class="{'bg-[#FFFF01]' : paymentTiming < 0 && invoice.status === 'Unpaid'}">
           <span v-if="props.invoice.status === 'Paid'">PD: </span><span v-else>Due:</span> &nbsp; {{ paymentTiming }}
        </div>
        <div v-else class=" whitespace-nowrap flex justify-center" :class="{'bg-[#FFFF01]' : paymentTiming < 0 && invoice.status === 'Unpaid', 'underline cursor-pointer' : hasReminders}">
           <span v-if="props.invoice.status === 'Paid'">PD: </span><span v-else>Due:</span> &nbsp; {{ paymentTiming }}
        </div>
        <base-modal :is-form-visible="sentInvoiceRemindersVisible" @close="hideSentInvoiceReminders">
                    <template #header>
                        <div>
                            Invoice #{{ invoice.invoice_number }} Sent Reminders
                        </div>
                    </template>
                    <template #body>
                        <div class="max-h-[95vh] overflow-auto">
                            <SentInvoiceReminders :sent-reminders="invoice.sent_reminders" />
                        </div>
                    </template>
                </base-modal>
    </base-table-cell>
        <base-table-cell>
            {{ invoice.payment_type }}
        </base-table-cell>
        <!-- <base-table-cell>
            {{ invoice.invoice_service }}
        </base-table-cell> -->
        <base-table-cell>
            {{ invoice.invoice_for.substr(0, 120) }} <span v-if="invoice.invoice_for.length > 120">...</span>
        </base-table-cell>
        <base-table-cell>
            <InvoiceIsRetainerFeeCell :invoice="invoice" @refetch-invoices="fetchNewInvoices" />
        </base-table-cell>
        <base-table-cell>
            <div class="flex justify-end w-full">
                {{ invoiceAmount }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="flex justify-end w-full">
                {{ amountPaid }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="flex justify-end w-full" :class="[{'bg-[#FF0300]' : balanceDue != '$0.00' && isOverdue}, {'bg-[#FFFF01]' : balanceDue != '$0.00' && !isOverdue}]" >
                {{ balanceDue }}
            </div>
        </base-table-cell>
        <base-table-cell>
            <InvoiceAccountingPostsCell :invoice="invoice" @refetch-invoices="fetchNewInvoices" :show-posts="showPosts" />
        </base-table-cell>
        <base-table-cell>
            <div class="flex justify-center" ref="itemCount" @mouseover="showPopover" @mouseleave="hidePopover">
                <span :class="{' underline decoration-dotted' : invoice.items.length > 0}">{{ invoice.items.length }}</span>
                <base-popover-hover-dark 
              v-if="isPopoverVisible"
              :xPosition="xPosition"
              :yPosition="yPosition"
              class="max-w-fit"
            >
              <template v-slot:title>
                Items:
              </template>
              <template v-slot:body>
                    <base-table class="w-full" >
                            <template #table-header>
                                <base-table-header>Item #</base-table-header>
                                <base-table-header>Product Name</base-table-header>
                                <base-table-header>Description</base-table-header>
                                <base-table-header>Quantity</base-table-header>
                                <base-table-header>Price</base-table-header>
                                <base-table-header>Total</base-table-header>
                            </template>
                            <template #table-body>
                                <base-table-row class=" bg-green-200" v-for="(item, idx) in invoice.items" :key="item.id">
                                    <base-table-cell><span class="">{{ idx + 1 }}</span></base-table-cell>
                                    <base-table-cell>{{ item.product_name }}</base-table-cell>
                                    <base-table-cell>{{ item.description }}</base-table-cell>
                                    <base-table-cell>{{ item.quantity }}</base-table-cell>
                                    <base-table-cell>{{ item.price }}</base-table-cell>
                                    <base-table-cell>{{ item.total }}</base-table-cell>
                                </base-table-row>
                            </template>
                        </base-table>
              </template>
            </base-popover-hover-dark>
                <!-- <base-button v-if="invoice.items.length > 0" @click="toggleItemVisibility">{{ itemText }}</base-button>
                <span v-else>--</span> -->
            </div>
        </base-table-cell>
        <base-table-cell>
            <div class="flex items-center space-x-2">
                <base-button @click="openForm">Mark Paid</base-button>
                <base-modal :is-form-visible="isFormVisible" @close="closeForm">
                    <template #header>
                        <h2 class="text-lg">
                            Edit Invoice {{ invoice.invoice_number }}
                        </h2>
                    </template>
                    <template #body>
                        <InvoiceForm :invoice="invoice" @refetch-invoices="fetchNewInvoices" @close="closeForm"/>
                    </template>
                </base-modal>
                <base-button @click="markSent">Mark Sent</base-button>
            </div>
        </base-table-cell>
    </base-table-row>

</template>

<script>
export default {
    name: 'invoice-row'
}
</script>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import BasePopoverHoverDark from '../BasePopoverHoverDark.vue'
import InvoiceForm from './InvoiceForm.vue';
import AllClientInvoices from './AllClientInvoices.vue'
import InvoiceAccountingPostsCell from './InvoiceAccountingPostsCell.vue';
import InvoiceIsRetainerFeeCell from './InvoiceIsRetainerFeeCell.vue';
import SentInvoiceReminders from './SentInvoiceReminders.vue'
import { markInvoiceSent } from '@/services/Invoice';
import { formatDateTwoDigitYear } from '@/composables/formatDate';

const props = defineProps({
    invoice: Object,
    showPosts: Object
})

const store = useStore()
const $toast = useToast()

const emit = defineEmits(['refetch-invoices'])

function fetchNewInvoices() {
    emit('refetch-invoices')
}

function showSentInvoiceReminders() {
    sentInvoiceRemindersVisible.value = true
}

function hideSentInvoiceReminders() {
    sentInvoiceRemindersVisible.value = false
}

const allClientInvoicesVisible = ref(false)

function showAllClientInvoices() {
    allClientInvoicesVisible.value = true
}

function hideAllClientInvoices() {
    allClientInvoicesVisible.value = false
}

const sentInvoiceRemindersVisible = ref(false)


const xPosition = ref(0)
const yPosition = ref(0)

const isPopoverVisible = ref(false)

const hasReminders = computed(() => {
    return props.invoice.sent_reminders.length > 0
})

const itemCount = ref(null)

const isFormVisible = ref(false)

function openForm () {
    isFormVisible.value = true
}

function closeForm() {
    console.log('closing')
    isFormVisible.value = false
}

function showPopover(){
    if(props.invoice.items.length > 0){
        isPopoverVisible.value = true
        xPosition.value = itemCount.value.getBoundingClientRect().left - 500
        yPosition.value = itemCount.value.getBoundingClientRect().top
    }
}

async function markSent(){
    const token = store.state.auth.token
    const result = await markInvoiceSent(props.invoice.id, token)
    if (result.status == 200){
        $toast.open({
            message: 'Marked invoice sent',
            type: 'success',
            duration: 3000
        })
        emit('refetch-invoices')
    } else {
        $toast.open({
            message: 'Could not mark invoice sent',
            type: 'error',
            duration: 3000
        })
    }
}

function hidePopover(){
    isPopoverVisible.value = false
    xPosition.value = 0;
    yPosition.value = 0;
}

const itemsVisible = ref(true)
const allAccountingPostsVisible = ref(true)

// watch(props.showItems, () => {
//     if(props.showItems.value){
//         itemsVisible.value = true
//     } else {
//         itemsVisible.value = false
//     }
// })

const isOverdue = computed(() => {
    const overdueStatuses = ['Unpaid', 'unpaid']
    const dueDate = new Date(props.invoice.due_date).getTime()
    const today = new Date().getTime()
    return (today > dueDate) && overdueStatuses.includes(props.invoice.status)
})

const invoiceAmount = computed(() => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(props.invoice.invoice_amount);

})
const amountPaid = computed(() => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(props.invoice.amount_paid);
})

const balanceDue = computed(() => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(props.invoice.invoice_amount - (props.invoice.amount_paid ? props.invoice.amount_paid : 0));
})

const dateDelta = computed(() => {
    const date1 = new Date(props.invoice.invoice_date);
    const date2 = new Date(props.invoice.due_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays
})

const paymentTiming = computed(() => {
    if(props.invoice.date_paid){
        const date1 = new Date(props.invoice.due_date);
        const date2 = new Date(props.invoice.date_paid);
        const diffTime = (date1 - date2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        return diffDays
    } else {
        const date1 = new Date(props.invoice.due_date);
        const date2 = new Date();
        const diffTime = (date1 - date2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        return diffDays
    }
})


function toggleItemVisibility() {
    itemsVisible.value = !itemsVisible.value
}
function setAccountingPostsVisibility(value) {
    allAccountingPostsVisible.value = value
}

const returnDate = (invoiceDate) => {
        let date = new Date(invoiceDate)
        date.setDate(date.getDate() + 1)
        return date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit'})
    }

const itemText = computed(() => {
    if(itemsVisible.value){
        return "-"
    } else {
        return "+"
    }
})

const accountingPostsText = computed(() => {
    if(allAccountingPostsVisible.value){
        return "-"
    } else {
        return "+"
    }
})

</script>

<style>
.itemsTable-enter-active,
.itemsTable-leave-active {
  transition: all 0.2s ease-in;
}
.itemsTable-enter-from {
    transform: scaleY(0);
    transform-origin: top;
}
.itemsTable-leave-to {
  transform: scaleY(0);
  transform-origin: top;
}
</style>