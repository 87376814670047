<template>
    <div class="flex flex-col w-full border-y border-b-2 border-gray-300 bg-gray-50" >
        <div class="bg-gray-50 flex items-center justify-between space-x-1  text-gray-700  border-gray-300 p-2">
           <div class="flex justify-between items-center w-full">
                <div class="flex flex-wrap space-x-1 items-center">
                    {{ progressUpdateNumber }}. {{ companyName }} {{ companyCityState }}
                </div>
                <div class="flex justify-end w-fit items-center h-full">
                    <div v-if="progressUpdate.unsaved" class="w-2 h-2 bg-yellow-600 rounded-full"></div>
                    <div v-else class="w-2 h-2 bg-gray-50 rounded-full"></div>
                </div>
           </div>
            <div class="cursor-pointer p-1 rounded-full hover:bg-gray-300" @click="removeProgressUpdate">
                <XMarkSVG
                    stroke-width="3"
                    stroke="gray"
                />
            </div>
        </div>
        <textarea placeholder='Write progress update notes here' class="w-full p-2 border border-gray-300 bg-white" rows="4" v-model="progressUpdate.body"></textarea>
        <!-- <div class="w-full">

        </div> -->
        <!-- <div class="">           
            <div class="flex space-x-2 w-full justify-start items-start py-2 pl-5  border-gray-300" >
                <div class="pt-6 pl-2 pr-4">
                    <div v-if="progressUpdate.unsaved" class="w-2 h-2 bg-yellow-600 rounded-full"></div>
                    <div v-else class="w-2 h-2 bg-gray-50 rounded-full"></div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch, reactive } from 'vue';
import XMarkSVG from '../svg-components/XMarkSVG.vue';

const props = defineProps(['progressUpdate', 'resetDefaultProgressUpdate', 'index', 'reloadDefaults', 'resetReport'])

const emit = defineEmits(['update-progress-update', 'remove-progress-update'])

const defaultProgressUpdate = ref(props.progressUpdate ? props.progressUpdate : null)

// const resetDefaultUpdate = ref(props.resetDefaultProgressUpdate ? props.resetDefaultProgressUpdate : false)

const progressUpdate = reactive({
    id: defaultProgressUpdate.value ? defaultProgressUpdate.value.id : null,
    body: defaultProgressUpdate.value ? defaultProgressUpdate.value.body : null,
    company_id: props.progressUpdate ? props.progressUpdate.company.id : null,
    unsaved: defaultProgressUpdate.value ? defaultProgressUpdate.value.unsaved : false,
    company: props.progressUpdate ? props.progressUpdate.company : null,
})

// watch(() => props.progressUpdate, () => {
//     if(resetDefaultUpdate.value) {
//         resetProgressUpdateToDefault()
//     }
// })

watch(() => props.reloadDefaults, ()=> {
    defaultProgressUpdate.value = props.progressUpdate ? props.progressUpdate : null
    if(progressUpdate.body != defaultProgressUpdate.value.body){
        progressUpdate.unsaved = true
    } else {
        progressUpdate.unsaved = false
    }
})

watch(() => props.resetReport, () => {
    resetProgressUpdateToDefault()
})

watch(progressUpdate, () => {
    if(defaultProgressUpdate.value) {
        console.log(progressUpdate.body, defaultProgressUpdate.value.body)
        console.log(progressUpdate.body !== defaultProgressUpdate.value.body)
        if(progressUpdate.body != defaultProgressUpdate.value.body){
            progressUpdate.unsaved = true
        } else {
            progressUpdate.unsaved = false
        }
    }
    updateProgressUpdate(progressUpdate)
}, {deep:true})

watch(() => props.resetReport, () => {
    
})

const progressUpdateNumber = computed(() => {
    return props.index + 1
})

const companyName = computed(() => {
    return props.progressUpdate.company.company_name
})

const companyCityState = computed(() => {
    if(props.progressUpdate.company.city && props.progressUpdate.company.state) {
        return `(${props.progressUpdate.company.city}, ${props.progressUpdate.company.state})`
    } else if(props.progressUpdate.company.city) {
        return `(${props.progressUpdate.company.city})`
    } else if(props.progressUpdate.company.state) {
        return `(${props.progressUpdate.company.state})`
    } else {
        return ''
    }
})

function removeProgressUpdate() {
    emit('remove-progress-update', props.progressUpdate.id)
}

function updateProgressUpdate(progressUpdateData) {
    emit('update-progress-update', progressUpdateData)
}

function resetProgressUpdateToDefault() {
    defaultProgressUpdate.value = props.progressUpdate
    progressUpdate.id = defaultProgressUpdate.value ? defaultProgressUpdate.value.id : null
    progressUpdate.body = defaultProgressUpdate.value ? defaultProgressUpdate.value.body : null
    progressUpdate.company_id = props.progressUpdate ? props.progressUpdate.company.id : null
    progressUpdate.unsaved = defaultProgressUpdate.value ? defaultProgressUpdate.value.unsaved : false
    progressUpdate.company = props.progressUpdate ? props.progressUpdate.company : null
    // resetDefaultUpdate.value = false
}
</script>