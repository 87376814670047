<template>
    <div class="flex flex-col">
        <div class="whitespace-nowrap">
            {{ targetDueDay }}
        </div>
        <div v-if="differentDueDays">
            {{ differentDueDays }}
        </div>

    </div>
</template>

<script>
export default {
    name: "engagement-target-invoice-day-cell"
}
</script>

<script setup>
import { computed, ref, defineProps } from 'vue';
import { formatDateWithWeekday } from '@/composables/formatDate';

const props = defineProps({
    engagement: Object
})

const targetDueDay = computed(() => {
    if(props.engagement.target_invoice_day){
        return formatDateWithWeekday(props.engagement.target_invoice_day.target_day.replace(/-/g, '/'))
    } else {
        return "--"
    }
})

const differentDueDays = computed(() => {
    if(props.engagement.target_invoice_day){
        let returnString = ""
        if(props.engagement.target_invoice_day.due_date != props.engagement.target_invoice_day.initial_due_date){
            returnString += `(DD: ${formatDateWithWeekday(props.engagement.target_invoice_day.initial_due_date.replace(/-/g, '/'))}) `
        }
        if(props.engagement.target_invoice_day.target_day != props.engagement.target_invoice_day.initial_day){
            returnString += `(ID: ${formatDateWithWeekday(props.engagement.target_invoice_day.initial_day.replace(/-/g, '/'))}) `
        }
        return returnString
    } else {
        return null
    }
})





</script>