<template>
    <div>
        <div ref="invoiceTable">
            <div @click="showForm" v-if="mostRecentInvoice">
                <span class="underline hover:cursor-pointer">{{ mostRecentInvoice.invoice_number }}:</span> {{ formatDateTwoDigitYear(mostRecentInvoice.invoice_date) }} ({{ formatDateMonthAndDay(mostRecentInvoice.due_date) }})
            </div>
            <div v-else>
                --
            </div>
        </div>
        <base-modal 
        :is-form-visible="isFormVisible" @close="closeForm"
            >
              <template v-slot:header>
                Retainer Invoices for {{ engagement.client.client_name }}:
              </template>
              <template v-slot:body>
                <div class="max-h-[95vh] overflow-auto">
                    <SimpleInvoiceTable :invoices="engagement.invoices" />
                </div>
              </template>
            </base-modal>
    </div>
</template>

<script>
export default {
    name: "engagement-most-recent-invoice-cell"
}
</script>

<script setup>
import { computed, ref, defineProps } from 'vue';
import { formatDateTwoDigitYear, formatDateMonthAndDay } from '@/composables/formatDate';
import BasePopoverHoverDark from '../BasePopoverHoverDark.vue';
import SimpleInvoiceTable from '../invoice_components/SimpleInvoiceTable.vue';

const props = defineProps({
    engagement: Object
})

const isFormVisible = ref(false)

function showForm(){
    isFormVisible.value = true
}

function closeForm(){
    isFormVisible.value = false
}

const itemCount = ref(null)

const mostRecentInvoice = computed(() => {
    if(props.engagement.invoices.length > 0){
        let lastInvoice = props.engagement.invoices.filter((invoice) => invoice.sent_date)[0]
        return lastInvoice
    } else {
        return null
    }
})



</script>