import axios from 'axios';
import { BASE_URL } from './api';

export async function getCRMReports(payload) {
    return axios({
        method: "get",
        url: `${BASE_URL}/crm_reports/${payload.endpoint}`,
        headers: {
            'Authorization': `Token ${payload.token}`
        }
    })
    .then(response => {
        console.log(response)
        return response.data
    })
    .catch(error => console.error(error))
}

export async function createCRMReport(token, requestData) {
    console.log('createCRMReport')
    console.log(requestData)
    return axios({
        method: "post",
        url: `${BASE_URL}/crm_reports/`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: requestData
    })
    .then(response => {
        // console.log(response)
        return response
    })
    .catch(error => console.error(error))
}

export async function updateCRMReport(token, reportId, requestData) {
    return axios({
        method: "put",
        url: `${BASE_URL}/crm_reports/${reportId}`,
        headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        },
        data: requestData
    })
    .then(response => {
        // console.log(response)
        return response
    })
    .catch(error => console.error(error))
}