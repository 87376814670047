<template>
        <!-- <div :style="pageStyling"> -->
    <div>
        <transition
            enter-active-class="transition duration-400 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-400 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            class="h-full"
        >
        </transition>
        <base-card>
            <InvoiceFilters @change-invoice-filters="setInvoiceFilters" @change-ordering="setOrder"></InvoiceFilters>
            <div class="inline">
              <div class="flex text-sm px-2.5 items-center space-x-2">
                <div>
                  Active Sorting:
                </div>
                <base-pill class="bg-amber-300 flex items-center justify-between space-x-2 p-2 mb-0.5 " v-if="orderBy.length > 0">
                  <div>{{ orderBy }}</div><button @click="clearSort">✕</button> 
                </base-pill>
              </div>
              
            </div>
        </base-card>
        <base-card>
        <div class="flex flex-col text-sm text-gray-900 h-[85vh]">
            <div class="flex flex-col justify-start lg:flex-row w-full mb-2">
                <div class="px-3 h-full flex w-full lg:w-fit flex-row justify-start py-2 border-b border-light-primary lg:py-0 lg:border-none items-center align-middle text-center 0">
                </div>
                <div class="flex flex-row items-center justify-between py-2 border-b border-light-primary lg:py-0 lg:border-none w-full h-full px-2 ">
                    <base-pagination-header
                      class="w-full"
                      @load-data="loadInvoices(fetchFilter)"
                      @get-next="getNext"
                      @go-to-first="goToFirst"
                      @get-prev="getPrev"
                      @go-to-last="goToLast"
                      @jump-to-page="jumpToPage"
                      @set-page-size-filter="setPageSizeFilter"
                      :page_filter="pageSizeFilter"
                      :page="page"
                      :numPages="totalPages"
                      :isLoading="isLoading"
                    >
                    <template v-slot:message>
                        <div class="pl-3 pt-1 whitespace-nowrap">
                        {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total Invoices | {{ totalPaid }} Paid | {{ totalUnpaid }} Unpaid | Total Balance: {{ invoiceTotal }} 
                        </div>
                    </template>
                    </base-pagination-header>
                    <div>
                      <base-button v-if="showAllPosts.value" @click="setShowAllPosts(false)">Hide All Posts</base-button>
                      <base-button v-else @click="setShowAllPosts(true)">Show All Posts</base-button>
                    </div>
                </div>
            </div>
            <div v-if="invoices.length > 0" class="h-full overflow-y-auto">
            <base-table>
                <template #table-header>
                <base-table-header :key="header.value" :header="header.value" :class="header.class" v-for="header in tableHeadersList">
                  <div class="flex  items-center justify-between space-x-1">
                    <div>
                      {{ header.value }}
                    </div>
                    <div v-if="header.orderFieldName">
                      <div @click="changeOrdering(header.orderFieldName)" v-if="noOrdering(header.orderFieldName)" class="text-sm flex flex-col space-y-0" >
                        <div class=" hover:cursor-pointer">▲</div>
                        <div class=" hover:cursor-pointer">▼</div>
                      </div>
                      <div @click="changeOrdering(header.orderFieldName)" v-else-if="orderedAscending(header.orderFieldName)">
                        <div class=" hover:cursor-pointer">▲</div>
                      </div>
                      <div @click="changeOrdering(header.orderFieldName)" v-else-if="orderedDescending(header.orderFieldName)">
                        <div class=" hover:cursor-pointer">▼</div>
                      </div>
                    </div>
                  </div>
                </base-table-header>
                </template>

                <template #table-body>
                <InvoiceRow
                    :key="invoice.id"
                    :invoice="invoice"
                    :show-posts="showAllPosts"
                    @refetch-invoices="loadInvoices"
                    v-for="invoice in invoices"
                            />
                </template>
            </base-table>
            </div>
        </div>
        </base-card>
    </div>
</template>

<script>
export default {
    name: 'invoices-table'
}
</script>

<script setup>
import {getInvoices} from '@/services/Invoice.js'
import {useStore} from 'vuex';
import {ref, reactive, shallowReactive, computed, watch} from 'vue';
import InvoiceFilters from './InvoiceFilters.vue';
import InvoiceRow from './InvoiceRow.vue';
import BaseCard from "@/components/BaseCard";

const store = useStore()
const isLoading = ref(false);
const awaitingSearch = ref(false);
const shouldLoadInvoices = ref(false)
const showAllPosts = reactive({
  value: false,
  changeCount: 0
})
const tableHeadersList = ref([
    {value: 'Invoice #', class: "", orderFieldName: "invoice_number"},
    // 'ID',
    {value: 'Status', class: "", orderFieldName: "status"},
    // {value: 'Wave Status', class: ""},
    {value: 'Client', class: "", orderFieldName: "engagement__client__client_name"},
    {value: 'Invoice Date', class: "text-center", orderFieldName: "invoice_date"},
    {value: 'Due Date', class: "text-center", orderFieldName: "due_date"},
    {value: 'Sent Date', class: "text-center"},
    {value: 'Effective PMT Term', class: "text-center"},
    {value: 'Date Paid', class: "text-center", orderFieldName: "date_paid"},
    {value: 'Payment Timing', class: "text-center"},
    {value: 'Payment Type', class: "text-center"},
    // {value: 'Service', class: ""},
    {value: 'For', class: ""},
    {value: 'Is Retainer Fee', class: ""},
    {value: 'Amount Due', class: ""},
    {value: 'Amount Paid', class: ""},
    {value: 'Balance', class: ""},
    {value: 'Notes', class: ""},
    {value: 'Items Count', class: ""},
    {value: 'Actions', class: ""}
])

function setShowAllPosts(showOrHide) {
  showAllPosts.value = showOrHide
  showAllPosts.changeCount++
}

const invoiceFilters = reactive({
    client: null,
    status: {value: "unpaid"},
    dueDate: null,
    isSent: {value: "True"}
  });

const pageSizeFilter = reactive({
    pageSizeFilter: 50
  })

function noOrdering(fieldName) {
  if(!orderBy.value.includes(fieldName) && !orderBy.value.includes('-' + fieldName)){
    return true
  } else {
    return false
  }
}

function orderedDescending(fieldName){
  if(orderBy.value.includes('-' + fieldName)){
    return true
  } else {
    return false
  }  
}

function orderedAscending(fieldName){
  if(orderBy.value.includes(fieldName)){
    return true
  } else {
    return false
  }  
}

function changeOrdering(fieldName) {
  if(orderedAscending(fieldName)){
    const index = orderBy.value.indexOf(fieldName)
    orderBy.value.splice(index, 1)
  } else if(orderedDescending(fieldName)) {
    const index = orderBy.value.indexOf('-' + fieldName)
    orderBy.value[index] = fieldName
  } else {
    orderBy.value.push('-' + fieldName)
  }
}

const invoices = ref([])
const next = ref(null);
const prev = ref(null);
const count = ref(null);
const page = ref(1);
const pageSize = ref(null);
const totalPaid = ref(0)
const totalUnpaid = ref(0)
const totalPages = ref(0)
const totalInvoiceAmountDue = ref(0)
const orderBy = ref(["-due_date"])

const fetchFilter = computed(function () {
    let query = new URLSearchParams({
    });
    if (invoiceFilters.client && invoiceFilters.client.value) {
        invoiceFilters.client.value.forEach(client => {
          query.append("client", client)
        })
    }
    if (invoiceFilters.dueDate && invoiceFilters.dueDate.value) {
        invoiceFilters.dueDate.value.forEach(dueDate => {
          query.append("due_range", dueDate)
        })
    }
    if (invoiceFilters.status && invoiceFilters.status.value) {
        query.append("status", invoiceFilters.status.value)
    }
    if (invoiceFilters.isSent && invoiceFilters.isSent.value) {
        query.append("is_sent", invoiceFilters.isSent.value)
    }
    if (pageSizeFilter.pageSizeFilter) {
      query.append("page_size", pageSizeFilter.pageSizeFilter); // To append more data
    }
    if(orderBy.value.length > 0){
      query.append("order_by", orderBy.value.join(','))
    }
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    // console.log(url);
    return url
  })

async function loadInvoices(queryParams = fetchFilter.value) {
    isLoading.value = true;
    shouldLoadInvoices.value = true
    const token = store.state.auth.token 
    const result = await getInvoices(token, queryParams);
    if(shouldLoadInvoices.value) {
      invoices.value = result.results;
      next.value = result.next ? `&page=${result.page + 1}` : null
      prev.value = result.prev ? `&page=${result.page - 1}` : null
      count.value = result.count
      page.value = result.page
      pageSize.value = result.results.length
      totalPages.value = result.num_pages
      totalPaid.value = result.paid_count
      totalUnpaid.value = result.unpaid_count
      totalInvoiceAmountDue.value = result.total_invoice_amount_due
      isLoading.value = false;
    }
  }

function setPageSizeFilter(target=null){
    if(target) {
      target == "clear" ? (pageSizeFilter.pageSizeFilter = 50) : (pageSizeFilter.pageSizeFilter = target);
      loadInvoices(fetchFilter.value)
    }
}

function setInvoiceFilters(updatedFilters) {
    invoiceFilters.client = updatedFilters.client;
    invoiceFilters.status = updatedFilters.status;
    invoiceFilters.dueDate = updatedFilters.dueDate;
    invoiceFilters.isSent = updatedFilters.isSent;
  }

const invoiceTotal = computed(() => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(totalInvoiceAmountDue.value);
})

function getNext() {
    if (next.value) {
      loadInvoices(fetchFilter.value + next.value)
    } else {
      alert("No next page")
    }
  }
  
function getPrev() {
    if (prev.value) {
      loadInvoices(fetchFilter.value + prev.value)
    } else {
      alert("No prev page")
    }
  }

function jumpToPage(targetPage) {
    loadInvoices(fetchFilter.value + `&page=${targetPage}`)
  }

const viewingFromNbr = computed(function () {
  return (page.value * pageSizeFilter.pageSizeFilter + 1) - pageSizeFilter.pageSizeFilter
  })
  
  
const viewingToNbr = computed(function () {
    if (page.value === totalPages.value) {
      return count.value;
    } else {
      return page.value * pageSize.value
    }
  })

 function goToFirst(){
    loadInvoices(fetchFilter.value + `&page=${1}`)
  }
  
function goToLast(){
    loadInvoices(fetchFilter.value + `&page=${totalPages.value}`)
  }

watch(() => invoiceFilters, () => {
    if(!awaitingSearch.value) {
          shouldLoadInvoices.value = false
          setTimeout(() => {
            loadInvoices(fetchFilter.value);
            awaitingSearch.value = false;
          }, 2000); // 2.5 sec delay
        }
        awaitingSearch.value = true
}, {deep: true})

watch(() => orderBy, () => {
    if(!awaitingSearch.value) {
          shouldLoadInvoices.value = false
          setTimeout(() => {
            loadInvoices(fetchFilter.value);
            awaitingSearch.value = false;
          }, 1000); // 2.5 sec delay
        }
        awaitingSearch.value = true
}, {deep: true})

function clearSort() {
  orderBy.value = []
}

loadInvoices()

function setOrder(fieldNames) {
  orderBy.value = fieldNames
}

</script>