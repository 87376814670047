<template>
    <div class="h-full flex flex-col justify-center items-center w-1/3">
        <div class="text-dark-primary tracking-wider text-2xl font-light border-b border-gray-300 w-full flex justify-center pb-2">
            CRM Reporting Dashboard
        </div>
        <div class="text-sm flex items-center w-full pl-2 pb-1 pt-4 font-semibold tracking-widest text-gray-600">
            Client
        </div>
        <type-ahead-box
            placeholder="Choose Client"
            nullLabel="No Results"
            :default-value="clientId"
            :options="clientOptions"
            @update-value="updateClientId"
        ></type-ahead-box>
        <!-- <div class="flex justify-center items-end pt-6 w-full">
            <button class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="viewReport">View Client CRM</button>
        </div> -->
        <!-- <div class="flex items-center justify-between space-x-2 py-7 px-2 border-b border-gray-300 tracking-wider w-full">
            <div class="flex space-x-1">
                <input 
                    type="radio" 
                    class="cursor-pointer accent-dark-primary"
                    value="create-draft"
                    v-model="actionType"
                />
                <label for="create-draft">Open New Draft</label>
            </div>
            <div class="flex space-x-1">
                <input 
                    type="radio" 
                    class="cursor-pointer accent-dark-primary"
                    value="edit-draft"
                    v-model="actionType"
                />
                <label for="edit">Edit Existing Draft</label>
            </div>
            <div class="flex space-x-1">
                <input 
                    type="radio" 
                    class="cursor-pointer accent-dark-primary"
                    value="read-only"
                    v-model="actionType"
                />
                <label for="read-only">View Closed Report</label>
            </div>
        </div>
        <div v-if="actionType=='create-draft'" class="w-full h-20 flex flex-col justify-between">
            <div class="flex flex-col h-full justify-end items-center pt-4">
                <button class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="viewReport">Open New Draft</button>
            </div>
        </div>
        <div v-else-if="actionType=='edit-draft'" class="w-full h-20 flex flex-col justify-between items-center">
            <type-ahead-box
                v-if="clientId"
                class="pt-4"
                placeholder="Choose Report Draft"
                nullLabel="No Report Drafts"
                :default-value="reportId"
                :options="draftReportOptions"
                @update-value="updateReportId"
            ></type-ahead-box>
            <div v-else
                class="border text-gray-600 bg-gray-200 border-gray-300 p-[5px]  mt-4  w-full"
            >
                Select Client To Get Report Options
            </div>
            <div class="flex justify-center items-end h-full">
                <button v-if="reportId" class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="viewReport">Edit Draft</button>
                <button v-else class="bg-gray-300  text-gray-600 tracking-wider font-medium px-3 py-1.5 rounded-sm" disabled>Edit Draft</button>
            </div>
        </div>
        <div v-else-if="actionType=='read-only'" class="w-full h-20 flex flex-col justify-between items-center">
            <type-ahead-box
                v-if="clientId"
                class="pt-4"
                placeholder="Choose Report"
                nullLabel="No Closed Reports"
                :default-value="reportId"
                :options="closedReportOptions"
                @update-value="updateReportId"
            ></type-ahead-box>
            <div v-else
                class="border text-gray-600 bg-gray-200 border-gray-300 p-[5px] mt-4 w-full"
            >
                Select Client To Get Report Options
            </div>
            <div class="flex justify-center items-end h-full">
                <button v-if="reportId" class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="viewReport">View Report</button>
                <button v-else class="bg-gray-300  text-gray-600 tracking-wider font-medium px-3 py-1.5 rounded-sm" disabled>View Report</button>
            </div>
        </div> -->
        <div class="w-full h-32 flex flex-col justify-between items-center">
            <div class="text-sm flex items-center w-full pl-2 pb-1 pt-4 font-semibold tracking-widest text-gray-600">
                Campaigns
            </div>
            <type-ahead-multiselect
                v-if="clientId"
                :placeholder="campaignPlaceholder"
                nullLabel="No Results"
                :default-value="campaigns"
                :options="campaignOptions"
                @update-value="updateCampaigns"
            ></type-ahead-multiselect>
            <div v-else
                class="border text-gray-600 bg-gray-200 border-gray-300 p-[5px] w-full"
            >
                Select Client To Filter Campaigns
            </div>
            <div  class="flex justify-center items-end h-full">
                <button v-if="campaigns.length > 0" class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="viewReport">View Client Report Builder</button>
                <button v-else class="bg-gray-300  text-gray-600 tracking-wider font-medium px-3 py-1.5 rounded-sm" disabled>View Client Report Builder</button>
            </div>
        </div>
    </div>
</template>

<script setup> 
import { ref, defineProps, defineEmits, watch, computed } from 'vue';

const props = defineProps([
    'clientOptions',
    'closedReportOptions', 
    'draftReportOptions',
    'campaignOptions', 
    'selectedClientId', 
    'selectedReportId',
    'selectedCampaigns'
])

const emit = defineEmits([
    'select-client', 
    'select-report',
    'select-campaigns', 
    'view-report'
])

const clientId = ref(props.selectedClientId)
const reportId = ref(props.selectedReportId)
const actionType = ref('create-draft')


watch(() => clientId.value, () => {
    emit('select-client', clientId.value)
})

watch(() => reportId.value, () => {
    emit('select-report', reportId.value)
})

watch(() => actionType.value, () => {
    reportId.value = null
})

function updateClientId(clientValue) {
    clientId.value = clientValue
    if(clientValue == null) {
        reportId.value = null
        // campaigns.value = []
    }
}

function updateReportId(reportValue) {
    reportId.value = reportValue
}

function viewReport() {
    emit('view-report')
}

const campaigns = ref(props.selectedCampaigns)

watch(() => campaigns.value, () => {
    if(campaigns.value) {
        emit('select-campaigns', campaigns.value)
    }
})
watch(() => props.selectedCampaigns, () => {
    campaigns.value = props.selectedCampaigns
})

function updateCampaigns(campaignIds) {
    campaigns.value = campaignIds
}


const campaignPlaceholder = computed(() => {
    let label
    if(props.campaignOptions.length == 1) {
        label = 'Campaign'
    } else {
        label = 'Campaigns'
    }
    if(props.selectedCampaigns.length == props.campaignOptions.length) {
        return `All ${props.selectedCampaigns.length} Campaigns Selected`
    } else if(props.selectedCampaigns.length > 0) {
        return `${props.selectedCampaigns.length} of ${props.campaignOptions.length} ${label} Selected`
    } else {
        return 'No Campaigns Selected'
    }
})

</script>