<template>
  <div ref="filterContainer" class="w-full">
    <!-- Filter Component -->

    <base-card>
      <PMAFilters
        @change-referral-filters="setApprovalFilters"
        @set-changed-referral-filters="setChangedApprovalsFilters"
        :companyOptions="companyFilterOptions"
        :visibleObject="isDropDownVisible"
      ></PMAFilters>
    </base-card>

    <!-- Header Component -->

    <base-card>
      <base-pagination-header
        @load-data="loadApprovals(fetchFilter.value, true)"
        @get-next="getNext"
        @go-to-first="goToFirst"
        @get-prev="getPrev"
        @go-to-last="goToLast"
        @jump-to-page="jumpToPage"
        @set-page-size-filter="setPageSizeFilter"
        :page_filter="approvalsFilters"
        :page="page"
        :numPages="num_pages"
        :isLoading="isLoading"
      >
        <template v-slot:message>
          <div class="flex items-center">
            <div class="pl-3 pt-1">
              {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }} Total PMAs
            </div>
            <base-button @click="generateCSV" class="underline ml-2">⬇</base-button>
          </div>
        </template>
      </base-pagination-header>

      <!-- Table Component -->

      <div class="h-full overflow-y-auto border">
        <base-table-full class="table-auto">
          <template #table-header>
            <base-table-header class="text-center" :key="header.value" :header="header" v-for="header in tableHeadersList"> 
              <div class="flex  items-center justify-between space-x-1">
                <div>
                  {{ header.value }}
                </div>
                <div v-if="header.orderFieldName">
                  <div @click="changeOrdering(header.orderFieldName)" v-if="noOrdering(header.orderFieldName)" class="text-sm flex flex-col space-y-0" >
                    <div class=" hover:cursor-pointer">▲</div>
                    <div class=" hover:cursor-pointer">▼</div>
                  </div>
                  <div @click="changeOrdering(header.orderFieldName)" v-else-if="orderedAscending(header.orderFieldName)">
                    <div class=" hover:cursor-pointer">▲</div>
                  </div>
                  <div @click="changeOrdering(header.orderFieldName)" v-else-if="orderedDescending(header.orderFieldName)">
                    <div class=" hover:cursor-pointer">▼</div>
                  </div>
                </div>
              </div>
            </base-table-header>
          </template>

          <template #table-body>
            <PMARow
              v-for="approval in approvals"
              :key="approval.id"
              :approval="approval"
              :resetObject="resetFilters"
            ></PMARow>
          </template>
        </base-table-full>
      </div>
    </base-card>
  </div>
</template>

<script setup>
// imports

import { ref, computed, onMounted, onUnmounted, reactive, watch } from "vue";
import { getPartsManufacturerApprovals } from "../services/PMAList.js";
import { useStore } from "vuex";
import { getCompanyNameFilterChoices, getPMAsForCSV } from "@/services/PMAList.js";
import { createCSV } from "@/composables/createCSV.js";
import PMARow from "./pma_page/table/PMARow.vue";
import PMAFilters from "./pma_page/PMAFilters.vue";

// constants/variables

const store = useStore();

const filterContainer = ref(null);
const tableHeadersList = ref([
  {value: "Company Name", orderFieldName: "company_name"},
  {value: "Supplement Date", orderFieldName: "supplement_date"},
  {value: "Part Name", orderFieldName: "part_name"},
  {value: "Make"},
  {value: "Model"},
  {value: "FAA Approval Basis"},
  {value: "Location"},
]);

const next = ref();
const prev = ref();
const count = ref();
const num_pages = ref();
const page_size = ref();
const page = ref(1);
const approvals = ref([]);

const orderBy = ref([])


const companyFilterOptions = ref([]);
const isLoading = ref(false);
const changedApprovalsFilters = ref(false);

const approvalsFilters = reactive({
  companyFilter: "",
  makeFilter: "",
  modelFilter: "",
  partNameFilter: "",
  supplimentDateFilter: "",
  pageSizeFilter: 50,
});
const isDropDownVisible = reactive({
  dropdownVisible: false,
  objectHasChanged: true,
});
const resetFilters = reactive({
  buttonHit: false,
});

// computed values

// const viewingFromNbr = computed(function () {
//   return page.value * page_size.value + 1 - page_size.value;
// });

// const viewingToNbr = computed(function () {
//   if (page.value === num_pages.value) {
//     return count.value;
//   } else {
//     return page.value * page_size.value;
//   }
// });

const viewingFromNbr = computed(function () {
  // return page.value * page_size.value + 1 - page_size.value;
  return (
    page.value * approvalsFilters.pageSizeFilter +
    1 -
    approvalsFilters.pageSizeFilter
  );
});

const viewingToNbr = computed(function () {
  if (page.value === num_pages.value) {
    return count.value;
  } else {
    // return page.value * page_size.value;
    return page.value * approvalsFilters.pageSizeFilter;
  }
});

const fetchFilter = computed(function () {
  let query = new URLSearchParams({});

  if (approvalsFilters.supplimentDateFilter) {
    query.append("date", approvalsFilters.supplimentDateFilter);
  }
  if (approvalsFilters.companyFilter) {
    query.append("company_name", approvalsFilters.companyFilter);
  }
  if (approvalsFilters.makeFilter) {
    query.append("make", approvalsFilters.makeFilter); // To append more data
  }
  if (approvalsFilters.modelFilter) {
    query.append("model", approvalsFilters.modelFilter); // To append more data
  }
  if (approvalsFilters.partNameFilter) {
    query.append("part_name", approvalsFilters.partNameFilter); // To append more data
  }
  if (approvalsFilters.pageSizeFilter) {
    query.append("page_size", approvalsFilters.pageSizeFilter); // To append more data
  }
  if(orderBy.value.length > 0){
      query.append("order_by", orderBy.value.join(','))
    }
  let url;
  let queriesCount = 0;
  for (let value of query.values()) {
    queriesCount = queriesCount + 1;
  }
  if (queriesCount > 0) {
    url = "?" + query.toString();
  } else {
    url = "?";
  }
  return url;
});

// async functions

async function loadApprovals(endpoint = fetchFilter.value, retainSpot) {
  isLoading.value = true;
  resetFilters.buttonHit = !resetFilters.buttonHit;
  let payload = {
    token: store.state.auth.token,
    endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint,
  };
  const response = await getPartsManufacturerApprovals(payload);
  next.value = response.next ? `&page=${response.page + 1}` : null;
  prev.value = response.prev ? `&page=${response.page - 1}` : null;
  count.value = response.count;
  page.value = response.page;
  num_pages.value = response.num_pages;
  approvals.value = response.approvals;
  page_size.value = response.page_size;
  isLoading.value = false;
}

async function setApprovalFilters(updatedFilters) {
  approvalsFilters.companyFilter = updatedFilters.value.companyFilter;
  approvalsFilters.makeFilter = updatedFilters.value.makeFilter;
  approvalsFilters.modelFilter = updatedFilters.value.modelFilter;
  approvalsFilters.partNameFilter = updatedFilters.value.partNameFilter;
  approvalsFilters.supplimentDateFilter =
    updatedFilters.value.supplimentDateFilter;
  await loadApprovals(fetchFilter.value);
}

async function setPageSizeFilter(target) {
  target == "clear"
    ? (approvalsFilters.pageSizeFilter = 50)
    : (approvalsFilters.pageSizeFilter = target);
  await loadApprovals(fetchFilter.value);
}

async function setCompanyFilterOptions() {
  let payload = {
    token: store.state.auth.token,
    endpoint: "",
  };
  const result = await getCompanyNameFilterChoices(payload);
  companyFilterOptions.value = ["---------"];
  result.company_names.forEach((company) => {
    companyFilterOptions.value.push(company);
  });
}

function orderedAscending(fieldName){
  if(orderBy.value.includes(fieldName)){
    return true
  } else {
    return false
  }  
}

function orderedDescending(fieldName){
  if(orderBy.value.includes('-' + fieldName)){
    return true
  } else {
    return false
  }  
}

function noOrdering(fieldName) {
  if(!orderBy.value.includes(fieldName) && !orderBy.value.includes('-' + fieldName)){
    return true
  } else {
    return false
  }
}

function changeOrdering(fieldName) {
  if(orderedAscending(fieldName)){
    const index = orderBy.value.indexOf(fieldName)
    orderBy.value.splice(index, 1)
  } else if(orderedDescending(fieldName)) {
    const index = orderBy.value.indexOf('-' + fieldName)
    orderBy.value[index] = fieldName
  } else {
    orderBy.value.push('-' + fieldName)
  }
}

const awaitingSearch = ref(false);

watch(() => orderBy, () => {
    if(!awaitingSearch.value) {
          setTimeout(() => {
            loadApprovals(fetchFilter.value);
            awaitingSearch.value = false;
          }, 1000); // 1 sec delay
        }
        awaitingSearch.value = true
}, {deep: true})

// functions

function getNext() {
  if (next.value) {
    loadApprovals(fetchFilter.value + next.value);
  } else {
    alert("No next page");
  }
}

function getPrev() {
  if (prev.value) {
    loadApprovals(fetchFilter.value + prev.value);
  } else {
    alert("No previous page");
  }
}

function goToFirst() {
  if (page.value != 1) {
    loadApprovals(fetchFilter.value + `&page=${1}`);
  } else {
    alert("At first page");
  }
}

function goToLast() {
  if (num_pages.value != page.value) {
    loadApprovals(fetchFilter.value + `&page=${num_pages.value}`);
  } else {
    alert("At last page");
  }
}

function jumpToPage(targetPage) {
  loadApprovals(fetchFilter.value + `&page=${targetPage}`);
}

function setChangedApprovalsFilters() {
  changedApprovalsFilters.value = true;
}

function handleClickOutside(event) {
  if (filterContainer.value && filterContainer.value.contains(event.target)) {
    isDropDownVisible.dropdownVisible = false;
    isDropDownVisible.objectHasChanged = !isDropDownVisible.objectHasChanged;
  }
}

async function generateCSV(){
  isLoading.value = true
  const token = store.state.auth.token
  if(count.value > 150000){
    alert('Cannot generate a CSV for more than 150,000 records. Please contact the Tech Team if you need further assistance.')
    isLoading.value = false
    return
  } else {
    const fields = [
    "company_name",
    "status",
    "cfr_part_reference",
    "pma_holder_number",
    "office_of_primary_responsibility",
    "supplement_number",
    "raw_supplement_date",
    "supplement_date",
    "part_name",
    "approved_replacement_for_part_number",
    "make",
    "model",
    "faa_approval_basis",
    "address",
    "city",
    "state",
    "zip",
    "country",
    "cfr_subpart_appendix_reference",
    "cfr_section_reference",
    ]
    const response = await getPMAsForCSV(fetchFilter.value, token)
    // console.log('response', response)
    if(response.status == 200){
      createCSV(fields, response.data.pmas, 'pma')
      isLoading.value = false
    }

  }
}

// life-cycle

onMounted(() => {
  loadApprovals();
  setCompanyFilterOptions();
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>
