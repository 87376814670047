<template>
    <Teleport to="body">
        <div v-show="printPreviewIsVisible" class="modal h-full fixed overflow-y-scroll bg-gray-200 flex justify-center w-[1632px]">
            <div class="flex fixed print:hidden bg-gray-50 shadow-md h-14 border-b justify-center items-center border-gray-300 z-10 w-full">
                <div @click="hidePrintPreview" class="hover:bg-gray-200 rounded-sm h-full px-0.5 md:px-3 flex justify-center items-center cursor-pointer print:hidden">
                    <ChevronLeftSVG 
                        fill-color="#25274D"
                    />
                </div>
                <div @click="handlePrint" class="hover:bg-gray-200 rounded-sm h-full flex px-0.5 md:px-3 justify-center items-center cursor-pointer print:hidden">
                    <PrintSVG
                        fill-color="#25274D"
                    />
                </div>
            </div>
            <div class=" bg-gray-200 relative flex justify-center w-[1632px] top-14">
                <div ref="componentRef" class="bg-white w-full flex flex-col h-full">
                    <div class="flex items-center justify-center h-[2100px] break-after-page p-24 w-full bg-white">
                        <div class="flex flex-col items-center justify-center h-full w-full px-12 bg-dark-secondary">
                            <div class="text-5xl flex items-center justify-center font-extralight tracking-widest whitespace-nowrap text-white py-4 w-1/2  border-b border-gray-50">
                                {{ clientName }}
                            </div>
                            <div class="text-2xl flex justify-center items-center w-full tracking-wider whitespace-nowrap font-medium text-gray-50 py-4">
                                Outreach Overview {{ today }}
                            </div>
                            <div class="w-[300px] h-[360px] absolute bottom-20 flex justify-center">
                                <SheddCapitalSVG 
                                    fill-color="white"
                                />
                            </div>
                        </div>
        
                    </div>
                    <div class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white">
                        <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                            <div class='w-full flex space-x-2 justify-start'>
                                <div>All Campaigns</div>
                                <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                                    {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                                </div>
                            </div>
                            <div class="w-[200px] flex justify-end">
                                <SheddCapitalSVG 
                                    fill-color="#1F2937"
                                />
                            </div>
                        </div>
                        <CRMOverview
                            class="h-[1800px]"
                            :printView="true"
                            :clientName="clientName"
                            :campaigns="campaigns"
                            :selectedCompanies="selectedCompanies"
                            :contactedCompanies="contactedCompanies"
                            :notContactedCompanies="notContactedCompanies"
                            :respondedCompanies="respondedCompanies"
                            :noResponseCompanies="noResponseCompanies"
                        />
                    </div>
                    <div 
                        v-for="(companyBatch, index) in selectedCompaniesBatches"
                        :key="index"
                        class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white">
                        <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                            <div class='w-full flex space-x-2 justify-start'>
                                <div>All Campaigns</div>
                                <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                                    {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                                </div>
                            </div>
                            <div class="w-[200px] flex justify-end">
                                <SheddCapitalSVG 
                                    fill-color="#1F2937"
                                />
                            </div>
                        </div>
                        <div class="w-full overflow-clip h-[1800px]">
                            <CRMTargetList
                                :print-view="true"
                                :selectedCompanies="companyBatch"  
                                :campaigns="campaigns"
                                :allCRMActions="allCRMActions"
                                :allCRMStatuses="allCRMStatuses"
                                :companyIdsToUpdate="companyIdsToUpdate"
                            />
                        </div>
                    </div>
                                        <!-- <div
                        v-for="campaign in campaigns"
                        :key="campaign"
                        class="w-full"
                    >
                        <div class="w-full bg-white flex flex-col h-[2100px] break-after-page p-24">
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div>  
                            <CRMOverview
                                class="h-[1800px]"
                                :printView="true"
                                :clientName="clientName"
                                :campaigns="campaigns"
                                :selectedCompanies="companies.filter((company) => company.campaign.id == campaign)"
                                :contactedCompanies="contactedCompanies.filter((company) => company.campaign.id == campaign)"
                                :notContactedCompanies="notContactedCompanies.filter((company) => company.campaign.id == campaign)"
                                :respondedCompanies="respondedCompanies.filter((company) => company.campaign.id == campaign)"
                                :noResponseCompanies="noResponseCompanies.filter((company) => company.campaign.id == campaign)"
                            />
                        </div>
                        <div class="w-full bg-white flex flex-col h-[2100px] break-after-page p-24">
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div>
                        </div>
                        <div 
                            v-for="(companyBatch, index) in selectedCompaniesBatches"
                            :key="index"
                            class=" bg-white flex flex-col h-[2100px] break-after-page p-24 items-center w-full"
                            >
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div >
                            <div class="w-full overflow-clip h-[1800px]">
                                <CRMTargetList
                                :print-view="true"
                                :selectedCompanies="companyBatch"  
                                :campaigns="campaigns"
                            />
                            </div>

                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import{ ref, defineProps, computed } from 'vue';
import SheddCapitalSVG from '../svg-components/SheddCapitalSVG.vue';
import CRMOverview from './CRMOverview.vue';
import CRMTargetList from './CRMTargets.vue';
import PrintSVG from '../svg-components/PrintSVG.vue';
import { useVueToPrint } from "vue-to-print";
import ChevronLeftSVG from '../svg-components/ChevronLeftSVG.vue';

const props = defineProps([
    "today",
    "clientName",
    "campaigns",
    "campaignOptions",
    "numCompanies",
    "companyLabel",
    "numCampaigns",
    "campaignLabel",
    "selectedCompanies",
    "contactedCompanies",
    "notContactedCompanies",
    "respondedCompanies",
    "noResponseCompanies",
    "selectedCompaniesBatches",
])

const reportDate = computed(() => {
    const date = new Date()
    return `${date.getFullYear()} ${String(date.getMonth() + 1).padStart(2, '0')} ${String(date.getDate()).padStart(2, '0')}`
})

const printPreviewIsVisible = ref(false)
const componentRef = ref();

function showPrintPreview() {
    printPreviewIsVisible.value = true
}

function hidePrintPreview() {
    printPreviewIsVisible.value = false
}

function getCampaignName(campaignId) {
    let thisCampaign = props.campaignOptions.find((campaign) => campaign.value == campaignId)
    return thisCampaign.label
}

const { handlePrint } = useVueToPrint({
  content: componentRef,
  documentTitle: `${reportDate.value} ${props.clientName} - Outreach Report`,
  removeAfterPrint: true
});

</script>