<template>
  <div class="h-full fixed bg-black w-full">
    <div class="text-justify justify-center align-center grid grid-cols-4 w-full fixed h-full">
      <!-- <div></div> -->
      <div class="col-span-4 w-full h-full">
        <img class="object-cover w-full h-full" id="splash" src="@/assets/images/new-splash.jpg"/>
      </div>
      <!-- <div></div> -->
    </div>
    <div class="grid grid-cols-4 fixed bottom-0 right-0 w-full">
      <div></div>
        <div class="text-gray-100 border-t border-gray-100  py-6 px-5 text-lg sm:text-xl md:text-2xl lg:text-3xl flex justify-start col-span-4 font-oxanium">
          Welcome to Aerocle V2.
        </div>
      <div></div>
    </div>
    <div class="grid grid-cols-2 fixed top-[10%] w-full h-[42%] ">
      <div class="px-5">
        <div class="text-white text-large flex flex-col">
          <div class="py-1 border-b border-white tracking-wider font-semibold">Announcements:</div>
          <div class="py-1 text-base">
              <div class="flex space-x-2 py-1.5" v-for="announcement in displayedAnnouncements" :key="announcement.id">
                <div>{{ formatDate(announcement.announcement_date) }}</div>
                <div>{{ announcement.title }}</div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'guest-welcome',
  props: {
    msg: String
  }
}
</script>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { formatDate } from '@/composables/formatDate';
import { getAnnouncements } from '@/services/Announcement';

const store = useStore()

const announcements = ref([])
const today = ref(new Date())

const displayedAnnouncements = computed(() => {
  return announcements.value.filter((announcement) => { return new Date(announcement.display_start_date) <= today.value}).filter((announcement) => { return new Date(announcement.display_end_date) >= today.value})
})

async function loadAnnouncements() {
  // console.log('loadAnnouncements')
  const token = store.state.auth.token
  const result = await getAnnouncements(token)
  // console.log(result)
  announcements.value = result
}

loadAnnouncements()
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
