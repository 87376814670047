<template>
    <div class="w-full h-full flex bg-white px-4 flex-col">
        <div class="w-full text-sm text-gray-700 font-semibold tracking-widest py-3 space-x-2 flex justify-between items-center">
            <div class="flex justify-start w-[803px] min-w-[803px] items-center space-x-2 tracking-wider whitespace-nowrap pt-0.5 pr-2">
                <div class="flex justify-start items-center space-x-2">
                    <div class="w-fit cursor-pointer hover:bg-gray-300 rounded-full p-1" @click="refetch">
                        <div  class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#4B5563"
                                class=" w-6 h-6 hover:cursor-pointer"
                            >
                                <path
                                    class=""
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="">
                        {{ numCompanies }} {{ companyLabel }} / {{ numCampaigns }} {{ campaignLabel }}
                    </div>
                </div>
                <div class="font-normal flex space-x-2 items-center h-full tracking-normal text-xs text-gray-700 cursor-pointer">
                    <Switch
                        v-model="viewProgressUpdates"
                        :class="viewProgressUpdates ? 'bg-dark-secondary border border-dark-primary pr-6' : 'bg-gray-300 border border-gray-400 pr-6'"
                        class="relative inline-flex h-5 w-11 items-center rounded-full"
                    >
                        <span class="sr-only">View Progress Updates</span>
                        <span
                            :class="viewProgressUpdates ? 'translate-x-6 border-dark-primary' : 'translate-x-0.5 border-gray-400'"
                            class="inline-block h-4 w-4 transform rounded-full bg-white border  transition"></span>
                    </Switch>
                    <div class="text-gray-600 text-xs tracking-wider">View Progress Updates</div>
                    <!-- <div v-show="editView=='targetList'" @click="changeEditView('progressUpdate')">Make Progress Updates</div>
                    <div v-show="editView=='progressUpdate'" @click="changeEditView('targetList')">View All Target List Fields</div> -->
                </div>
            </div>
            <div class="w-full flex space-x-4 text-xs justify-end items-center">
                <Transition
                    class="duration-100 ease-out"
                    enter-from-class="transform -translate-y-full"
                    leave-to-class="transform -translate-y-full"
                    key="progress-update-toolbar"
                >
                    <div v-if="viewProgressUpdates" class="flex space-x-4 justify-start items-center w-full">
                        <!-- <div class="flex justify-start items-center space-x-2">
                            <div class="text-sm text-gray-700 font-semibold tracking-widest whitespace-nowrap">
                                CRM Report:
                            </div>
                            <div>
                                <type-ahead-box
                                    class="w-[275px] text-xs font-normal tracking-normal text-gray-800"
                                    placeholder="Load Existing Report"
                                    nullLabel="No Reports"
                                    :default-value="reportId"
                                    :options="reportOptions"
                                    @update-value="updateReportId"
                                ></type-ahead-box>
                            </div>
                        </div> -->
                        <div class="flex justify-start items-center space-x-2">
                            <div>
                                <button v-if="reportId" class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white whitespace-nowrap tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="emit('reset-from-report')">Reset To Last Save</button>
                                <button v-else disabled class="bg-gray-300 whitespace-nowrap text-gray-600 tracking-wider font-medium px-3 py-1.5 rounded-sm">Reset To Last Save</button>
                            </div>
                            <div>
                                <button class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white whitespace-nowrap tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="emit('add-all-companies')">Add All</button>
                            </div>
                            <div>
                                <button class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white whitespace-nowrap tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="emit('remove-all-companies')">Remove All</button>
                            </div>
                        </div>
                    </div>
                </Transition>
                <div class="text-xs w-full flex justify-end space-x-2">
                    <div v-if="numChanges > 0" class="flex items-center w-full justify-end font-normal space-x-2 text-gray-600">
                        <div>
                           {{ numChanges }} Unsaved {{ changeLabel }}
                        </div>
                        <button ref="saveBoundary" class="bg-dark-secondary whitespace-nowrap hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium p-1 rounded-sm" @click.prevent="saveReport(newReportName,'draft')" @click.right.prevent="showSaveMenu">
                            <SaveSVG :path-color="saveColor" />
                            <base-popover-click
                            class="z-30"
                                v-if="visibleSaveMenu"
                                :xPosition="xPosition"
                                :yPosition="yPosition"
                            >
                                <div class="flex flex-col text-gray-700 w-[120px] h-fit bg-white">
                                    <div class="flex p-1 hover:bg-[#EEF2FF]" @click.prevent="saveReportFromMenu(newReportName, 'draft')">
                                        Save Draft
                                    </div>
                                    <div class="flex p-1 hover:bg-[#EEF2FF]" @click.prevent="showPublishReportForm">
                                        Save & Publish
                                    </div>
                                </div>
                            </base-popover-click>
                        </button>
                    </div>
                    <div v-else class="flex items-center justify-end font-normal space-x-2 text-gray-600">
                        <button disabled class="bg-gray-300 text-gray-500 whitespace-nowrap tracking-wider font-medium p-1 rounded-sm">
                            <SaveSVG :path-color="saveColor" />
                        </button>
                    </div>
                </div>
            </div>
            <base-modal-small 
                    v-if="visiblePublishReportForm"
                    @close="hidePublishReportForm"
                >
                <template v-slot:header>
                    Save & Publish Report
                </template>
                <template v-slot:body>
                    <div class="w-full h-full flex font-normal text-gray-700 tracking-normal justify-center items-center">
                        <div class="flex flex-col w-[350px] justify-center space-y-4 items-center ">
                            <div class="w-full flex justify-center">Please Name Report to Publish</div>
                            <div class="w-full"><input type="text" v-model="newReportName" class="border w-full rounded-sm px-2 py-1 border-gray-500" placeholder="Report Name" /></div>
                            <div class="flex justify-center items-center">
                                <button @click.prevent="saveReportFromMenu(newReportName, 'closed')" class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white whitespace-nowrap tracking-wider font-medium px-3 py-1.5 rounded-sm">Save & Publish</button>
                            </div>
                        </div>
                    </div>
                </template>
            </base-modal-small>
        </div>
        <div class="w-full flex h-[calc(100vh-12rem)]">
            <CRMTargetList
                :condensedView="viewProgressUpdates"
                :reported-companies="companies"
                :campaigns="campaigns"
                :campaignOptions="campaignOptions"
                :allCRMActions="allCRMActions"
                :allCRMStatuses="allCRMStatuses"
                :company-ids-to-update="companyIdsToUpdate"
                @set-reported-event="setReportedEvent"
                @add-company-to-progress-update="addProgressUpdate"
                @refetch="refetch"
            />
            <Transition
                class="duration-200 ease-out"
                enter-from-class="transform translate-x-full"
                leave-to-class="transform translate-x-full"
                key="progress-update"
            >
                <CRMProgressUpdate
                    v-show="viewProgressUpdates"
                    :progressUpdates="progressUpdates"
                    :reload-defaults="reloadDefaults"
                    :reset-report="resetReport"
                    @remove-progress-update="removeProgressUpdate"
                    @update-progress-update="updateProgressUpdate"
                    @refetch="refetch"
                />
            </Transition>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch, computed } from 'vue';
import CRMTargetList from './CRMTargetList.vue';
import CRMProgressUpdate from './CRMProgressUpdate.vue';
import { Switch } from '@headlessui/vue';
import SaveSVG from '@/components/svg-components/SaveSVG.vue';
import { useClickOutside } from '@/composables/useClickOutside';

const props = defineProps([
    'companies',
    'reportOptions',
    'selectedReport',
    'clientName',
    'campaigns',
    'campaignOptions',
    'allCRMActions',
    'allCRMStatuses',
    'companyIdsToUpdate',
    'progressUpdates',
    'numUnsavedProgressUpdates',
    'numDeletedProgressUpdates',
    'reloadDefaults',
    'resetReport'
])
const emit = defineEmits([
    'set-reported-event',
    'save-report',
    'refetch',
    'add-all-companies',
    'remove-all-companies',
    'add-last-report-notes',
    'reset-bulk-progress-update',
    'add-progress-update',
    'remove-progress-update',
    'update-progress-update',
    'select-report',
    'reset-from-report',
])

const visibleTargetList = ref(false)
// const editView = ref('targetList')
const viewProgressUpdates = ref(false)

const visibleSaveMenu = ref(false)
const visiblePublishReportForm = ref(false)

const saveBoundary = ref(null)
const xPosition = ref(0)
const yPosition = ref(0)

const newReportName = ref(null)

useClickOutside(saveBoundary, ()=> {
  hideSaveMenu()
})


const reportId = ref(props.selectedReport ? props.selectedReport.value : null)

const numCompanies = computed(() => {
    return props.companies ? props.companies.length : 0
})

const companyLabel = computed(() => {
    if(numCompanies.value == 1) {
        return 'Target'
    }
    return 'Targets'
})

const numChanges = computed(() => {
    return props.numUnsavedProgressUpdates + props.numDeletedProgressUpdates + props.companyIdsToUpdate.length
})

const changeLabel = computed(() => {
    return numChanges.value == 1 ? 'Change' : 'Changes'
})

const saveColor = computed(() => {
    return numChanges.value > 0 ? 'white' : '#6B7280'
})

// const reportedEventsToUpdate = computed(() => {
//     return props.companyIdsToUpdate ? props.companyIdsToUpdate.length  : 0
// })


const numCampaigns = computed(() => {
    return props.campaigns ? props.campaigns.length : 0
})

const campaignLabel = computed(() => {
    if(numCampaigns.value == 1) {
        return 'Campaign'
    }
    return 'Campaigns'
})

// function changeEditView(editViewValue) {
//     editView.value = editViewValue
// }


function saveReport(reportName, reportStatus) {
    console.log(reportName, reportStatus)
    if(!visibleSaveMenu.value){
        emit('save-report', reportName, reportStatus)
    }
}

function saveReportFromMenu(reportName, reportStatus) {
    console.log(reportName, reportStatus)
    emit('save-report', reportName, reportStatus)
    hidePublishReportForm()
}

function refetch() {
    emit('refetch')
}

function showSaveMenu() {
    visibleSaveMenu.value = true
    xPosition.value = saveBoundary.value.getBoundingClientRect().left - 100
    yPosition.value = saveBoundary.value.getBoundingClientRect().bottom
}

function hideSaveMenu() {
    visibleSaveMenu.value = false
    xPosition.value = 0
    yPosition.value = 0
}

function showPublishReportForm() {
    visiblePublishReportForm.value = true
}

function hidePublishReportForm() {
    visiblePublishReportForm.value = false
}

function setReportedEvent(company, event, autoCompute) {
    emit('set-reported-event', company, event, autoCompute)
}

function addProgressUpdate(companyID) {
    // console.log('CRMREportEditor: addCompanyProgressUpdate')
    emit('add-progress-update', companyID)
}

function removeProgressUpdate(progressUpdateID) {
    emit('remove-progress-update', progressUpdateID)
}

function updateProgressUpdate(progressUpdateData) {
    emit('update-progress-update', progressUpdateData)
}

function showTargetList() {
    visibleTargetList.value = true
}

function hideTargetList() {
    visibleTargetList.value = false
}

function updateReportId(reportValue) {
    reportId.value = reportValue
}

watch(() => reportId.value, () => {
    console.log('reportId changed')
    emit('select-report', reportId.value)
})
</script>

<style scoped>
.target-list-move,
.target-list-enter-active {
    transition: all 0.3s ease;
}
.target-list-leave-active {
  transition: all 0.3s ease;
}
.target-list-enter-from,
.target-list-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.target-list-leave-active {
    position: relative;
    transform: translateX(100px);
    /* position: relative; */
}
</style>