<template>
  <div class="modal-body relative  w-full flex flex-col h-full">
    <transition
            enter-active-class="transition duration-400 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-100 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            class="h-full"
        >
            <div v-if="isLoading" class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.7] flex flex-col justify-center items-center z-40"
                    tabindex="-1" >
                <div class="flex flex-col justify-center items-center p-4 rounded-md">
                <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#d3d3d3"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#2E9CCA"/>
                </svg>
                <div class="text-white p-4 whitespace-nowrap text-sm">
                    Please Wait While We Make Changes To {{ company.company_name }}.
                </div>
                </div>
            </div>
        </transition>
      <div class="h-full overscroll-none">
        <div  v-if="showCRMEditForm" class="py-3 px-1 rounded w-fit text-sm flex">
            <label class="bg-light-primary border-b border-b-light-primary text-gray-600 py-1 px-2 w-full flex justify-center items-center rounded-l-md h-8 text-center" for="selectedCampaign">Campaign: </label>
            <div class="w-fit whitespace-nowrap  rounded-tr-md rounded-br-md border border-light-primary px-2 flex items-center h-8 bg-gray-100 text-gray-600">
                {{ currentCampaign }} 
            </div>
        </div>
        <div v-else class="py-3 px-1 rounded w-fit text-sm flex">
            <label class="bg-light-primary border-b border-b-light-primary py-1 px-2 w-full flex justify-center items-center rounded-l-md h-8 text-center" for="selectedCampaign">Campaign: </label>
            <select name="selectedCampaign" class="block  rounded-tr-md rounded-br-md border border-light-primary p-1 h-8" id="" v-model="selectedCampaign">
                <option v-for="campaign in campaignOptions" :key="campaign.id" :value="campaign.id">{{ campaign.campaign_name }} <span v-if="campaign.id == company.campaign.id">(Current)</span> </option>
            </select>
        </div>
        <div class="border-y border-light-primary ml-3 ">
            <div class="flex justify-between items-center  h-12">
                <h2 class="text-sm tracking-wider font-semibold text-gray-800 flex items-center" v-if="mostRecentStatus">CRM Status:
                    <select v-if="selectedCampaign == company.campaign.id" name="addStatus" id="" class="p-1 border mx-3  border-light-primary w-fit rounded" :value="mostRecentStatus.crm_status.id"  @change="addCrmStatus">
                        <option v-for="option in statusOptions" :key="option.value" :value="option.value" >{{ option.label }} <span v-if="mostRecentStatus.crm_status.id == option.value"> - Updated {{ new Date(mostRecentStatus.created_on).toLocaleDateString() }}</span></option>
                    </select> 
                    <span class="mx-3" v-else>{{ mostRecentStatus.crm_status.status_name }}</span>
                    <span class="flex">
                        <div v-if="loadingCRMStatus" class="flex justify-start items-center space-x-2">                        
                            <svg aria-hidden="true" class="mx-1 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#d3d3d3"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#2E9CCA"/>
                            </svg>
                            <div>
                                Updating CRM Status...
                            </div>
                        </div>
                        <!-- <div v-else>
                            Updated on {{ new Date(mostRecentStatus.created_on).toLocaleDateString() }}
                        </div> -->
                    </span>
                </h2>
                <h2 class="text-sm flex items-center" v-else>CRM Status:
                    <select v-if="selectedCampaign == company.campaign.id" name="addStatus" id="" class="p-1 border mx-3  border-light-primary w-fit rounded"  @change="addCrmStatus">
                        <option v-for="option in statusOptions" :key="option.value" :value="option.value" >{{ option.label }}</option>
                    </select> 
                    <span class="mx-3" v-else>--</span>
                </h2>
                <button  class="bg-dark-secondary text-white hover:bg-dark-primary px-2 py-1 rounded" @click="toggleShowAll">{{ showAllStatusesText }}</button>
            </div>
            <base-table v-if="showAllStatuses" class="max-h-72 overflow-y-auto mb-2">
                <template #table-header>
                    <th :key="header.id" class="w-screen sticky -top-0.5 p-1 bg-white drop-shadow-sm h-full font-semibold tracking-wide" :header="header" v-for="header in statusTableHeadersList"> {{
                        header
                    }}
                    </th>
                </template>
                <template  #table-body>
                    <tr  v-for="crm_status in crm_statuses" class="border border-collapse"  :key="crm_status.id">
                        <td class="p-1">
                            {{ crm_status.crm_status.status_name }}
                        </td>
                        <td class="p-1">
                            {{ new Date(crm_status.created_on).toLocaleDateString() }}
                        </td>
                        <!-- <td class="p-1">
                            {{ crm_status.crm_status.high_level_grouping_for_report.high_level_grouping_name }}
                        </td> -->
                    </tr>
                </template>
            </base-table>
        </div>
        <div class="h-full ml-3 pb-1 mb-2">
            <div class=" mb-1 flex justify-between items-center h-12">
                <div class="flex items-center space-x-2 px-1">
                    <h2 class="text-sm tracking-wider font-semibold text-gray-800">Details: </h2>
                    <div class="flex space-x-5 w-full justify-start items-center">
                        <div class="flex justify-start items-center w-fit whitespace-nowrap">
                            <div class="mr-1 w-5 h-5 rounded bg-emerald-200 border border-gray-300">

                            </div>
                            <div>
                                Existing Detail
                            </div>
                        </div>
                        <div class="flex justify-start items-center w-fit whitespace-nowrap">
                            <div class="mr-1 w-5 h-5 rounded bg-sky-200 border border-gray-300">

                            </div>
                            <div>
                                Valid Row
                            </div>
                        </div>
                        <!-- <div class="flex justify-start items-center w-fit whitespace-nowrap">
                            <div class="mr-1 w-5 h-5 rounded bg-amber-100 border border-gray-300">

                            </div>
                            <div>
                                Empty Row
                            </div>
                        </div> -->
                        <div class="flex justify-start items-center w-fit whitespace-nowrap">
                            <div class="mr-1 w-5 h-5 rounded bg-red-400 border border-gray-300">

                            </div>
                            <div>
                                Invalid Row
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectedCampaign == company.campaign.id">
                    <button v-if="showCRMEditForm" class="bg-dark-secondary text-white hover:bg-dark-primary px-2 py-1 rounded" @click="closeCRMEditForm">Cancel</button>
                    <div v-else class=" flex justify-end items-center space-x-2">
                        <button class="bg-dark-secondary text-white px-2 py-1 rounded" @click="openCRMEditForm">Edit CRM Details</button>
                    </div>
                </div>
                <div v-else class="h-9"></div>
            </div>
            <div class="flex justify-between h-full" v-if="showCRMEditForm">
                <CRMEditForm 
                    :company="company"
                    :crmEventTypeOptions="crmEventTypeOptions"
                    :companyCampaignId="companyCampaignId"
                    :userOptions="userOptions"
                    :emptyCRMActions="emptyCRMActions"
                    @update-crm-events="bulkUpdateCompanyCRM"
                    @close-crm-edit-form="closeCRMEditForm"
                />
            </div>
            <div v-else class="h-full">
                <div class="w-full grid grid-cols-12 border-gray-300 rounded-t overflow-y-scroll ">
                    <!-- <div class="w-full bg-gray-50 border-y border-r border-l col-span-1 rounded-tl border-gray-300 px-3 py-1.5 font-semibold">
                        Type
                    </div> -->
                    <div class="w-full bg-gray-50 border-y border-r border-l col-span-2 border-gray-300 px-3 py-1.5 font-semibold">
                        Detail Name
                    </div>
                    <div class="w-full bg-gray-50 border-y border-r col-span-2 border-gray-300 px-3 py-1.5 font-semibold">
                        Due Date
                    </div>
                    <div class="w-full bg-gray-50 border-y border-r col-span-2 border-gray-300 px-3 py-1.5 font-semibold">
                        Resolved Date
                    </div>
                    <div class="w-full bg-gray-50 border-y border-r col-span-1 border-gray-300 px-3 py-1.5 font-semibold">
                        Owner (Created By)
                    </div>
                    <div class="w-full text-center bg-gray-50 border-y border-r col-span-1 border-gray-300 px-3 py-1.5 font-semibold">
                        Status
                    </div>
                    <div class="w-full bg-gray-50 border-y border-r col-span-3 border-gray-300 px-3 py-1.5 font-semibold">
                        Notes
                    </div>
                    <div class="w-full flex justify-center bg-gray-50 border-y border-r col-span-1 rounded-tr border-gray-300 px-3 py-1.5 font-semibold">
                        Corresp.
                    </div>
                </div>
                <div class="overflow-y-scroll h-96  max-h-[calc(100vh-13.5rem)] ">
                    <CRMEventRowReadOnly
                        v-for="action in openActions"
                        :key="action.id"
                        :event="action"
                    />
                    <CRMEventRowReadOnly
                        v-for="event in events"
                        :key="event.id"
                        :event="event"
                    />
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
    export default {
        name: 'crm-details-page'
    }
</script>

<style>
.eventTable-enter-active,
.eventTable-leave-active {
  transition: all 0.5s ease;
}
.eventTable-enter-from,
.eventTable-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>

<script setup>
import { defineProps, ref, computed, watch, defineEmits, onMounted} from 'vue';
import { useStore } from 'vuex';
import { addNewCrmStatus, addNewCrmAction } from '@/services/Company';
import {deleteAction } from '@/services/CrmAction'
import { getUsers } from '@/services/User';
import CRMEditForm from './company/CRMEditForm.vue';
import CRMEventRowReadOnly from './company/CRMEventRowReadOnly.vue';
import { updateCompanyCRMActions } from '@/services/Company';
import { useToast } from 'vue-toast-notification';

const store = useStore()
const $toast = useToast()

const props = defineProps({
    company: Object,
    campaigns: Array,
    crmStatuses: Array,
    crmActionTypes: Array,
})

const emit = defineEmits(['refetch-companies', 'updated'])

const formRows = ref([])

const showCRMEditForm = ref(false)

const isLoading = ref(false)

const loadingCRMStatus = ref(false)

const crmEventTypes = ref(props.crmActionTypes.filter(crmAction => crmAction.resolved_type == 'event'))
const crmEventTypeOptions = ref(props.crmActionTypes.map((crmActionType) => {return {value: crmActionType.id, label: crmActionType.action_name}}))


const userOptions = ref([
    {value: null, label: 'None'}
])

async function removeAction(actionId) {
    isLoading.value = true
    const token = store.state.auth.token
    await deleteAction(actionId, token)
    emit('refetch-companies')
}

const statusTableHeadersList = ref([
    'Status',
    'Status Date',
    // 'High Level Grouping'
])

function openCRMEditForm() {
    showCRMEditForm.value = true
}

function closeCRMEditForm() {
    showCRMEditForm.value = false
}

function addFormRow(){
    formRows.value.push(
        {key: Math.random(), company: props.company}
        )
}

function closeActionRow(index) {
    formRows.value.splice(index, 1)
}

const eventsTableHeadersList = ref([
    // {class: "w-3/14", label: 'Task Name'},
    {class: "w-2/12", label: 'Event'},
    // {class: "w-1/14", label: 'Event Status'},
    {class: "w-1/12 text-center", label: 'Event Date'},
    // {class: "w-1/14", label: 'Task Due Date'},
    // {class: "w-1/14", label: 'Task Status'},
    {class: "w-1/12 text-center", label: 'User'},
    // {class: "w-1/14", label: 'Task Resolved Date'},
    {class: "w-6/12", label: 'Event Summary Notes'},
    // {class: "w-5/14", label: 'Task Notes'},
    {class: "w-1/12 text-center", label: 'Event Details'},
    // {class: "w-2/14", label: 'Task Details'},
    {class: "w-1/12 text-center", label: 'Delete'},
])

const showAllStatuses = ref(false)

function toggleShowAll() {
    showAllStatuses.value = !showAllStatuses.value
}

const showAllStatusesText = computed(() => {
    if(showAllStatuses.value){
        return "Hide History"
    } else {
        return "Show History"
    }
})

const selectedCampaign = ref(props.company.campaign.id)

const eventStatus = ref('')

const emptyCRMActions = ref([])

const campaignOptions = ref([])

const currentCampaign = computed(() => {
    return props.company.campaign ? `${props.company.campaign.campaign_name} (Current)` : 'No Campaign'
})

const companyCampaignId = computed(() => {
    return props.company.campaign ? props.company.campaign.id : null
})

const crm_statuses = computed(() => {
    return props.company.crm_statuses.filter(crm_status => crm_status.campaign.id == selectedCampaign.value).sort((a, b) => {
        return new Date(b.created_on) - new Date(a.created_on)
    })
})

const mostRecentStatus = computed(() => {
    if(crm_statuses.value.length > 0){
        let recentStatus = crm_statuses.value.reduce((accumulator, currentValue) =>{
                let accDate = new Date(accumulator.created_on).getTime()
                let currDate = new Date(currentValue.created_on).getTime()
                if(accDate > currDate){
                    return accumulator
                } else {
                    return currentValue
                }
            })
        // console.log('recent status', recentStatus)
        return recentStatus
    } else {
        return null
    }
})

const events = computed(() => {
    let eventList = props.company.crm_actions.filter(action => action.campaign.id == selectedCampaign.value).filter(action => action.resolved_date).sort(function(a,b) {
        return new Date(b.resolved_date) - new Date(a.resolved_date)
    })

    if(eventStatus.value && eventStatus.value != "Overdue"){
        // console.log('eventstatus', eventStatus.value)
        eventList = eventList.filter(event => event.status == eventStatus.value)
    } else if (eventStatus.value && eventStatus.value == "Overdue"){
        eventList = eventList.filter((event) => {
            const dueDate = new Date(event.resolved_date).getTime()
            const today = new Date().getTime()
            return event.status == "Open" && today > dueDate
        })
    }

    return eventList
})

function sortByDueDate(a, b){
    if(a.due_date && b.due_date){
        return new Date(a.due_date) - new Date(b.due_date)
    } else if ((a.due_date && !b.due_date)){
        return -1
    } else if ((b.due_date && !a.due_date)){
        return 1
    } else {
        return a.action.action_name < b.action.action_name ? -1 : 0
    }
}




const openActions = computed(() => {
    let openActionList = props.company.crm_actions.filter(action => action.campaign.id == selectedCampaign.value).filter(action => !action.resolved_date).sort(sortByDueDate)

    return openActionList
})

// const completedActions = computed(() => {
//     let openActionList = props.company.crm_actions.filter(action => action.campaign.id == selectedCampaign.value).filter(action => ).sort(function(a,b) {
//         return new Date(b.resolved_date) - new Date(a.resolved_date)
//     })

//     return openActionList
// })

// const doneActions = computed(() => {
//     let doneActionList = props.company.crm_actions.filter(action => action.campaign.id == selectedCampaign.value).filter(action => action.action.resolved_type == 'action' && action.status == 'Done').sort(function(a,b) {
//         return new Date(b.due_date) - new Date(a.due_date)
//     })

//     return doneActionList
// })

onMounted(() => {
    calculateEmptyCRMEvents()
})

function calculateEmptyCRMEvents() {
    let existingCRMActionTypes = [...new Set(events.value.filter(event => event.campaign.id == companyCampaignId.value).map(event => event.action.id))]
    emptyCRMActions.value = []
    for(let actionType of crmEventTypes.value) {
        if(!existingCRMActionTypes.includes(actionType.id)){
            emptyCRMActions.value.push({
                action: actionType,
                action_id: actionType.id,
                action_notes: null,
                user_id: null,
                resolved_date: null,
                action_details: null,
                type: 3,
                id: Math.floor(Math.random() * -100000),
            })
        }
    }
}

function getCampaignOptions(){
    const campaignIds = props.company.crm_statuses.map(status => status.campaign.id)
    let filteredCampaigns = [props.company.campaign, ...props.campaigns.filter(campaign => campaignIds.includes(campaign.id) && campaign.id != props.company.campaign.id) ]
    campaignOptions.value = filteredCampaigns

}

const statusOptions = ref(
    [ {'value': null, label: '-----'}, 
        ...props.crmStatuses.map((status)=>{
            return {'value': status.id, 'label': status.status_name}
        })
    ]
)

async function addCrmStatus(event){
    loadingCRMStatus.value = true
    const token = store.state.auth.token
    const response = await addNewCrmStatus(props.company.id, event.target.value, token)
    if(response.status == 200) {
        emit('refetch-companies')
        $toast.open({
            message: `Successfully Updated CRM Status for Company ${props.company.id}.`,
            type: 'success',
            duration: 5000
        })
    }
    loadingCRMStatus.value = false
}

async function addSOPTask(sop){
    isLoading.value = true
    const token = store.state.auth.token
    let today = new Date()
    // let tomorrow = today.getDate() + 1
    // today.setDate(tomorrow)
    let user = store.state.auth.user.id
    if(sop == "Scott to Assist"){
        user = 3
    }
    const eventData = {
        action_id: sop.id,
        status: "Done",
        resolved_date: today.toJSON().substring(0,10),
        user: user
    }
    // console.log(eventData)
    await addNewCrmAction(props.company.id, eventData, token)
    emit('refetch-companies')
}

function validateCRMActionRows(actionRows) {
    let invalidCRMActionRows = []
    let validCRMActionRows = []
    for(let actionRow of actionRows) {
        if(actionRow.action_id) {
            validCRMActionRows.push(actionRow)
            // if(actionRow.resolved_date && actionRow.campaign_id && actionRow.status == 'Done') {
            //     // valid
            //     validCRMActionRows.push(actionRow)
            // } else if(actionRow.campaign_id && actionRow.status == 'Open'){
            //     validCRMActionRows.push(actionRow)
            // } else {
            //     // empty or invalid
            //     if(actionRow.user || actionRow.action_notes || actionRow.action_details) {
            //         // invalid
            //         invalidCRMActionRows.push(actionRow)
            //     }
            //     // empty
            // }
        } else {
            invalidCRMActionRows.push(actionRow)
        }
    }
    if(invalidCRMActionRows.length > 0) {
        // console.log('valid rows')
        // console.log(validCRMActionRows)
        // console.log('invalid rows')
        // console.log(invalidCRMActionRows)
        alert(`Validation Error: Found ${invalidCRMActionRows.length} invalid row(s). Please fix before continuing.`)
        return null
    }
    return validCRMActionRows
}

async function bulkUpdateCompanyCRM(companyCRMActions) {
    isLoading.value = true 
    // console.log('bulkUpdateCompanyCRM', props.company.id)
    let actionRows = validateCRMActionRows(companyCRMActions)
    if(actionRows) {
        const payload = {
            token: store.state.auth.token,
            formData: JSON.stringify(
            {
                crm_actions: actionRows
            }),
            companyId: props.company.id
        }
        // console.log('payload')
        // console.log(payload)
        const response = await updateCompanyCRMActions(payload)
        closeCRMEditForm()
        if(response.status == 200) {
            fetchNewCompanies()
            emit('updated')
            $toast.open({
                message: `Successfully Updated CRM Events for Company ${props.company.id}.`,
                type: 'success',
                duration: 5000
            })
        } else {
            $toast.open({
                message: `Unable to Update CRM Events for Company ${props.company.id}`,
                type: 'error',
                duration: 20000
            })
        }
    } else {
        isLoading.value = false
    }

}

function fetchNewCompanies(){
    isLoading.value = true
    emit('refetch-companies')
}

watch(props, () => {
    if(props.company){
        isLoading.value = false
    }
})

watch(()=> events.value, ()=> {
    calculateEmptyCRMEvents()
}, {deep: true})

async function setUserOptions() {
    let payload = {
        token: store.state.auth.token,
        endpoint: '?group_id=1&group_id=7&group_id=8&exclude_dev=True'
    }
    const result = await getUsers(payload)
    userOptions.value = [{value: null, label: 'None'}, ...result.users.map((user) => {
        return { value: user.id, label: `${user.first_name} ${user.last_name}`}
    })]
}

getCampaignOptions()
setUserOptions()
</script>