import axios from "axios";

import { BASE_URL } from "./api";

export async function getAnnouncements(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/announcements/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        throw error
    });

}